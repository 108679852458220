<template>
    <div class="HomeWho">
        <div class="title">
            Кто нам подходит
        </div>
        <Spacer48/>
        <Spacer16/>
        <div class="who">
            <div class="imgs">
                <img src='../../assets/images/landing/img.svg' alt='first'>
                <div class="txt">
                    <div class="subtitle">
                        Опытные мастера
                    </div>
                    <Spacer8/>
                    Подтвержденные сертификатами и дипломами навыки, 
                    которым вы долгое время обучались когда-то или только что, 
                    то поможет повысить стоимость ваших занятий и ускорит набор первых учеников
                </div>
            </div>
            <div class="imgs">
                <img src='../../assets/images/landing/img-2.svg' alt='second'>
                <div class="txt">
                    <div class="subtitle">
                        Начинающие наставники
                    </div>
                    <Spacer8/>
                    Если у вас есть навыки, но вы еще ими не делились, 
                    главное — уверенные знания в своем ремесле, а с остальным мы поможем
                </div>
            </div>
            <div class="imgs">
                <img src='../../assets/images/landing/img-3.svg' alt='third'>
                <div class="txt">
                    <div class="subtitle">
                        Визионеры
                    </div>
                    <Spacer8/>
                    Если вам хочется делиться не навыками, 
                    а знаниями, консультируйте и продвигайте свои идеи, 
                    монетизируйте их и помогайте новичкам
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Spacer48 from '../Spacers/SpacerModule48.vue';
    import Spacer16 from '../Spacers/SpacerModule16.vue';
    import Spacer8 from '../Spacers/SpacerModule8.vue';
    export default {
        name: 'HomeWho',
        components: {
            Spacer48,
            Spacer16,
            Spacer8,
        },
    }
</script>

<style scoped>
    .HomeWho {
        color: #525A7D;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

    }
    .imgs {
        object-fit: cover;
        width: 100%;
    }
    .title {
        font-size: 64px;
        font-weight: 550;
        line-height: 72px;
        letter-spacing: -0.02em;
        text-align: center;
    }
    .who {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }
    .who img {
        border-radius: 20px;
    }
    .subtitle {
        font-size: 24px;
        font-weight: 550;
        line-height: 28px;
        text-align: left;
    }
    .txt {
        padding-top: 20px;
        padding-right: 16px;
        padding-left: 16px;
    }
</style>
к