<template>
    <div>
        <input v-model="conferenceInputID" placeholder="nqz8nh" />
        <input v-model="conferenceInputPassWord" placeholder="m7tbqar6" />
        <input v-model="event_conference_id_input" placeholder="44" />

        <div @click="joinRoom" class="button">
            Join room
        </div>
        <div @click="btn" class="button">
            btn
        </div>
        <ul>
            <li v-for="participant in localParticioants" v-bind:key="participant.id"
                style="display: flex; flex-direction: column;">
                <div class="wrapper-video">
                    <div style="position:absolute;top: 15px;">
                        {{ participant.name }}
                    </div>
                    <video :ref="`video_${participant.id}`" :srcObject="participantStream[participant.id]" autoplay
                        playsinline class="video">
                    </video>
                </div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
// import Vue components
import {
    defineComponent,
    ref,
    watch,
    onMounted,
    onUnmounted
} from 'vue'

// import from salute sdk
import {
    getLocalDevices,
    ScreenShareUserCanceledError,
    createSdkToken,
    createJazzWebSdk,
    LocalDevicesManager,
    JazzSdk,
    handleEvent,
    handleQuery,
    JazzClient,
    createJazzClient,
    JazzRoom,
    USER_MEDIA_TYPE,
} from '@salutejs/jazz-sdk-web'

// import plugins from sdk plugins
import {
    getAudioOutputMixer,
    AudioOutputMixer,
    videoElementPoolPlugin,
    audioOutputMixerPlugin
} from '@salutejs/jazz-sdk-web-plugins';

import axios from 'axios';

export default defineComponent({
    name: 'ConferencePage',
    setup() {
        type Status = 'idle' | 'success' | 'error' | 'pending';
        const sdk = ref<JazzSdk | undefined>(undefined);

        const audioOutputMixer = ref<AudioOutputMixer | undefined>(undefined);
        const videoStream = ref<MediaStream | undefined>(undefined);
        const audioStream = ref<MediaStream | undefined>(undefined);
        const displayStream = ref<MediaStream | undefined>(undefined);

        const isMutedCamera = ref(true);
        const isMutedAudio = ref(true);
        const isMutedDisplay = ref(true);

        const cameraStatus = ref<Status>('idle');
        const audioStatus = ref<Status>('idle');
        const displayStatus = ref<Status>('idle');

        const videoElementRef = ref<HTMLVideoElement | null>(null);
        const displayVideoElementRef = ref<HTMLVideoElement | null>(null);

        const localDevices = ref<LocalDevicesManager | undefined>(undefined);

        const localParticipants = ref<any[]>([]);
        const participantStream = ref<{ [key: string]: any }>({});

        var jazzClient: JazzClient;

        const room = ref<JazzRoom | undefined>(undefined);

        const conferenceInputID = ref("nqz8nh");
        const conferenceInputPassWord = ref("m7tbqar6");
        const event_conference_id_input = ref("44");

        var currentEvent: any;

        onMounted(async () => {
            // fetching event info
            try {
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvent/?id='+event_conference_id_input); // must be replaced by params
                currentEvent = response.data;
                console.log(response.data)
                console.log(currentEvent)
            } catch (e) {
                console.log("error while fetching event info: ", e);
            }

            try {
                var sdkToken;
                sdk.value = await createJazzWebSdk({
                    plugins: [
                        videoElementPoolPlugin(),
                        audioOutputMixerPlugin(),
                    ],
                });
                console.log("sdk value: ", sdk.value);
                jazzClient = await createJazzClient(await sdk.value!, {
                    serverUrl: 'https://salutejazz.ru',
                });
                sdkToken = await createSdkToken('eyJwcm9qZWN0SWQiOiIxYzBmZjI1Mi01YTEzLTQ4OTItYjdiMy04ZDVjNmIxNjlhNzQiLCJrZXkiOnsia3R5IjoiRUMiLCJkIjoiYkZjWWNpcG5kSkFqM21rZXdyX1VVVm9HdTlueEZqQXlIZWUwWi1oNl9WYmEzR29QYzlEZzY1MzhHWHI1a191ZiIsInVzZSI6ImVuYyIsImNydiI6IlAtMzg0Iiwia2lkIjoiYjRlZmJkNGYtNmI4ZC00Mzc0LThjMGEtYWExOTg5ZTVlNzMyIiwieCI6Ijlwa0prZ3NVOU9SRzVpbVNVUk5XNlVDVk9BUjl5WEQtNkFaYXZOVFZ0LWJfOFRxMXhETjM3REpEelpPM3M2cFUiLCJ5IjoiWHQ1RzJZWmFiaEdjTWtMeU4yNThuNXQzMWR3ek9rZFVadk1SN3VKMThWRHgxdVFDamQ0YXA5aE5tamNJVDN4MyJ9fQ==', {

                    iss: 'Platforma34',
                    userName: 'DPKlitr',
                });
                console.log("sdkToken: ", sdkToken);
                await jazzClient.auth.loginBySdkToken(await sdkToken!.sdkToken);
                console.log("auth: ", jazzClient.auth.isAuthorised.get());
                console.log("auth: ", jazzClient.isNetworkOnline.get());
                console.log("JazzClient logged in: ");
                console.log("JazzClient auth status", jazzClient.auth.authStatus.get());
                console.log("sdk created, value: ", sdk.value);

                try {

                    localDevices.value = await getLocalDevices(await sdk.value!)
                } catch (error) {
                    console.log("Error while getting local devices\n", error);
                };
                if (!localDevices.value) {
                    return;
                }

                // handle mute track changed
                const unsubscribeMuteChange = handleEvent(
                    localDevices.value!.event$,
                    'muteTrackChanged',
                    (event) => {
                        const { payload } = event;
                        if (
                            payload.mediaType === 'displayScreen' &&
                            payload.stream === displayStream.value
                        ) {
                            isMutedDisplay.value = payload.isMuted;

                            if (payload.isMuted) {
                                displayStream.value = undefined;
                            }
                        }
                    },
                );

                // handle error Device Permissions
                // const unsubscribeErrorDevicePermission = handleEvent(
                //     localDevices.value!.event$,
                //     'errorDevicePermissions',
                //     (event) => {
                //         const { payload } = event;
                //         if (eventBus.value) {
                //             eventBus.value({
                //                 type: 'error',
                //                 payload: {
                //                     title: payload.message,
                //                 },
                //             });
                //         } else {
                //             console.error('Device permission error:', payload.message);
                //         }
                //     }
                // );

                onUnmounted(() => {
                    unsubscribeMuteChange();
                    // unsubscribeErrorDevicePermission();


                });
            } catch (error) {
                console.log("Error in initializing SDK: ", error);
            }
        });

        const cancelFn = ref<() => void>(() => { });
        cancelFn.value = () => {
            if (audioStream.value && audioOutputMixer.value) {
                audioOutputMixer.value.removeMediaStream(audioStream.value);
            }
        };

        onUnmounted(() => {
            cancelFn.value();
        });

        watch(
            [() => displayStream.value, () => isMutedDisplay.value],
            () => {
                if (displayVideoElementRef.value) {
                    const activeStream = !isMutedDisplay.value
                        ? displayStream.value
                        : null;
                    displayVideoElementRef.value.srcObject = activeStream || null;
                    displayVideoElementRef.value.load();
                    displayVideoElementRef.value.play().catch(() => { });
                }
            }
        );

        // TODO: rewrite using handleEvent
        const getParticipantsInfo = async () => {
            localParticipants.value = await room.value!.participants.get();
            console.log("localParticipants: ", localParticipants.value);
            for (const participant of localParticipants.value) {
                const stream = await room.value!.getVideoStream(participant.id, { source: "user" });
                participantStream.value[participant.id] = stream;
            }
        }

        const btn = async () => {
            try {
                axios.put('https://platforma34.tw1.ru/api/events/UpdateEvent/?id='+event_conference_id_input.value, {
                    'event_conference_id': conferenceInputID.value,
                    'event_conference_password': conferenceInputPassWord.value,
                });
            } catch (e) {
                console.log("AN ERROR ACCURED"+e);
            }
        }
        const joinRoom = async () => {
            try {

                console.log("Join room ");
                room.value = await jazzClient.conferences.join({
                    conferenceId: conferenceInputID.value,
                    password: conferenceInputPassWord.value,
                });

                console.log("Joined room ");
                try {
                    const [audioStreaml, videoStreaml] = await Promise.all([
                        localDevices.value!.getSelectedAudioInputStream({ isMuted: true }),
                        localDevices.value!.getSelectedVideoInputStream({ isMuted: true }),
                    ]);


                    console.log('Add mediaStreams to room');

                    room.value.setUserAudioInput(audioStreaml);
                    room.value.setUserVideoInput(videoStreaml);

                    const releaseMedia = () => {
                        console.log('Release mediaStreams');

                        localDevices.value!.releaseMediaStream(audioStreaml);
                        localDevices.value!.releaseMediaStream(videoStreaml);
                    };
                    console.log("Some log");

                    handleEvent(room.value.event$, 'destroy', releaseMedia, true);
                    // habdle participants video stream
                    const unsubscribeHandlingVideo = handleEvent(room.value.event$, 'addTrack', ({ payload }) => {
                        if (payload.mediaType === USER_MEDIA_TYPE.VIDEO) {
                            // Видеопоток всегда имеет принадлежность к участнику
                            // payload.participantId.forEach(element  => {
                            //     participantStream.value[payload.participantId] = payload.stream;
                            // });
                            console.log("payload.participantId: ", payload.participantId);

                            // Полученный MediaStream можно использовать для отображения видео
                            console.log("payload.stream: ", payload.stream);
                        }
                    });
                    const unsubscribeHandleParticipants = handleQuery(room.value.participants, (participants) => {
                        console.log(participants);
                    });
                    onUnmounted(() => {
                        unsubscribeHandlingVideo();
                        unsubscribeHandleParticipants();
                        console.log("UNMOUNTED");
                    })
                } catch (error) {
                    console.log('Media permission denied');
                }
            } catch (error) {
                console.log("Error with joining: ", error);
            }

        };
        return {
            isMutedCamera,
            isMutedAudio,
            isMutedDisplay,
            cameraStatus,
            audioStatus,
            displayStatus,
            videoElementRef,
            displayVideoElementRef,
            // toggleCamera,
            // toggleAudio,
            // toggleDesktop,
            joinRoom,
            getParticipantsInfo,
            localParticipants,
            participantStream,
            conferenceInputID,
            conferenceInputPassWord,
            event_conference_id_input,
            btn,
        };
    },
})
</script>

<style scoped>
.button {
    padding: 10px;
    background-color: orange;
}

.button:hover {
    cursor: pointer;
    background-color: green;
}
</style>