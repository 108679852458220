<template>
    <div class="dropdown">
      <button class="dropdown-button" @click="toggleDropdown">
        {{ selectedOption ? selectedOption : label }}
        <span class="arrow">&#9662;</span>
      </button>
      <div v-if="isOpen" class="dropdown-menu">
        <div v-for="option in options" :key="option" class="dropdown-item" @click="selectOption(option)">
          {{ option }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DropdownMenu',
    props: {
      label: {
        type: String,
        required: true
      },
      options: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        isOpen: false,
        selectedOption: null
      };
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      selectOption(option) {
        this.selectedOption = option;
        this.isOpen = false;
        this.$emit('select', option);
      }
    }
  }
  </script>
  
  <style scoped>
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  /* 
    padding: 10px 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: none;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    */
  }
  
  .dropdown-button .arrow {
    margin-left: 10px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 100%;
  }
  
  .dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .dropdown-item:hover {
    background: #f0f0f0;
  }
  </style>
