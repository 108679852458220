import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper-video" }
const _hoisted_2 = {
  ref: "displayVideoElementRef",
  autoplay: "",
  playsinline: "",
  class: "video"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JumpingDotLoaderWithShadows = _resolveComponent("JumpingDotLoaderWithShadows")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.currentStatusRef), 1),
    _cache[0] || (_cache[0] = _createElementVNode("div", null, "njkfdnvjkdfn", -1)),
    _createVNode(_component_JumpingDotLoaderWithShadows, {
      isLoading: (_ctx.currentStatusRef == 'Connecting to conference' || _ctx.currentStatusRef == 'Joining to conference'),
      "loading-text": _ctx.currentStatusRef
    }, null, 8, ["isLoading", "loading-text"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("video", _hoisted_2, null, 512)
    ])
  ]))
}