<template>
    <div class="new-event-form">
        <h3 class="section-title">Новое мероприятие</h3>
        <p class="section-description">
            Вы всегда сможете изменить детали мероприятия
        </p>

        <div class="cover-upload">
            <input type="file" @change="onFileChange" />
            <button class="upload-button" @click="uploadImage">
                📁 <span>Загрузите обложку</span>
            </button>
        </div>

        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="title">Напишите название</label>
                <input v-model="form.title" type="text" id="title" placeholder="Сияй изнутри" />
            </div>

            <div class="form-group">
                <label for="description">Добавьте описание</label>
                <textarea v-model="form.description" id="description" rows="4" placeholder="Сияй изнутри..."></textarea>
            </div>

            <div class="form-row">
                <div class="form-group">
                    <label for="date">Дата</label>
                    <input v-model="form.date" type="date" id="date" />
                </div>
                <div class="form-group">
                    <label for="time">Время</label>
                    <input v-model="form.time" type="time" id="time" />
                </div>
            </div>

            <div class="form-group">
                <label for="price">Назначьте цену (опционально)</label>
                <input v-model="form.price" type="number" id="price" placeholder="500 руб" />
            </div>

            <div class="form-group">
                <label for="discount">Добавьте скидку (опционально)</label>
                <input v-model="form.discount" type="number" id="discount" placeholder="50%" />
            </div>

            <div class="form-group">
                <label for="maxParticipants">Максимальное число человек</label>
                <input v-model="form.maxParticipants" type="number" id="maxParticipants" placeholder="200" />
            </div>

            <div class="form-group">
                <label for="minAge">Минимальный возраст</label>
                <input v-model="form.minAge" type="number" id="minAge" placeholder="18" />
            </div>

            <div class="form-group checkbox">
                <input v-model="form.isPublic" type="checkbox" id="isPublic" />
                <label for="isPublic">Публичное мероприятие</label>
            </div>

            <div class="button-group">
                <button type="submit" class="button primary">Создать</button>
            </div>

            <div v-if="imageUrl">
                <h3>Uploaded Image:</h3>
                <img :src="imageUrl" alt="Uploaded" />
            </div>
        </form>
    </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import axios from 'axios';

export default defineComponent({
    name: 'NewEventForm',
    setup() {
        const form = reactive({
            title: '',
            description: '',
            date: '',
            time: '',
            price: '',
            discount: '',
            maxParticipants: '',
            minAge: '',
            isPublic: false
        });

        let selectedFile = null;
        let imageUrl = null;

        const onFileChange = (event) => {
            selectedFile = event.target.files[0];
        };

        const submitForm = async () => {
            const formData = new FormData();
            formData.append("event_image", selectedFile);
            formData.append("event_title", form.title);
            formData.append("event_description", form.description);
            formData.append("event_date", form.date);
            formData.append("event_time", form.time);
            formData.append("event_price", form.price);
            formData.append("event_discount", form.discount);
            formData.append("event_totalSpots", form.maxParticipants);
            formData.append("event_ageRestriction", form.minAge);
            formData.append("event_public", form.isPublic);

            try {
                const response = await axios.post("https://platforma34.tw1.ru/api/events/AddEvent/", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                console.log("Form submitted successfully:", response.data);
                imageUrl = response.data.imageUrl;
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        };

        return {
            form,
            submitForm,
            onFileChange,
            imageUrl,
        };
    }
});
</script>

<style scoped>
.new-event-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.section-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.cover-upload {
    background-color: #f0e6ff;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.upload-button {
    background: none;
    border: none;
    color: #8162E9;
    cursor: pointer;
    font-size: 16px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 12px;
}

.form-row {
    display: flex;
    gap: 15px;
}

.form-row .form-group {
    flex: 1;
}

.checkbox {
    display: flex;
    align-items: center;
}

.checkbox input {
    margin-right: 10px;
}

.button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
}

.button.secondary {
    background-color: #f0f0f0;
    color: #333;
}

.button-group {
    display: flex;
    gap: 10px;
}

.button-group .button {
    flex: 1;
}

.button.outline {
    background-color: #fff;
    border: 1px solid #8162E9;
    color: #8162E9;
}

.button.primary {
    background-color: #8162E9;
    color: #fff;
}
</style>