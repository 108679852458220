<template>
    <div>
        <div>
            {{ currentStatusRef }}
        </div>
        <div>njkfdnvjkdfn</div>
        <JumpingDotLoaderWithShadows
            :isLoading="(currentStatusRef == 'Connecting to conference' || currentStatusRef == 'Joining to conference')"
            :loading-text="currentStatusRef" />

        <div class="wrapper-video">
            <video ref="displayVideoElementRef" autoplay playsinline class="video"></video>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, toRef, onUnmounted, ref, watch } from 'vue';
import JumpingDotLoaderWithShadows from '../components/Animations/JumpingDotLoaderWithShadows.vue';
import { JazzClient, JazzRoom, JazzSdk, createJazzWebSdk, createJazzClient, handleEvent, LocalDevicesManager, getLocalDevices, createSdkToken } from '@salutejs/jazz-sdk-web';
import { getAudioOutputMixer, AudioOutputMixer, videoElementPoolPlugin, audioOutputMixerPlugin } from '@salutejs/jazz-sdk-web-plugins';


export default defineComponent({
    name: 'ConferencePage2',
    components: {
        JumpingDotLoaderWithShadows,
    },
    props: {

        // TODO: rewrite using interface
        conferenceId: {
            type: String,
            default: '',
            required: false,
        },
        conferencePassword: {
            type: String,
            default: '',
            required: false,
        },
        currentStatus: {
            type: String,
            default: 'Connecting to conference',
            required: true,
        },
        userName: {
            type: String,
            default: 'undefined',
            requiered: false,
        }, 
        eventTitle: {
            type: String, 
            default: 'undefined',
            required: false
        }

    },
    /* eslint-disable */
    setup(props) {
        /* eslint-disable */
        const currentStatusRef = toRef(props.currentStatus)
        const sdk = ref<JazzSdk | undefined>(undefined);

        var jazzClient: JazzClient;
        var room: JazzRoom;

        const audioOutputMixer = ref<AudioOutputMixer | undefined>(undefined);
        const localDevices = ref<LocalDevicesManager | undefined>(undefined);
        const isMutedDisplay = ref(true);
        const displayStream = ref<MediaStream | undefined>(undefined);

        const displayVideoElementRef = ref<HTMLVideoElement | null>(null);
        
        watch(
            [() => displayStream.value, () => isMutedDisplay.value],
            () => {
                if (displayVideoElementRef.value) {
                    const activeStream = !isMutedDisplay.value
                        ? displayStream.value
                        : null;
                    displayVideoElementRef.value.srcObject = activeStream || null;
                    displayVideoElementRef.value.load();
                    displayVideoElementRef.value.play().catch(() => { });
                }
            }
        );

        async function initSdk() {
            try {
                sdk.value = await createJazzWebSdk({
                    plugins: [
                        videoElementPoolPlugin(),
                        audioOutputMixerPlugin(),
                        // logsPlugin({
                        // logLevel: 'debug',
                        // }),
                    ],
                });
                console.log("sdk:", sdk);
                console.log("sdk.value:", sdk.value);



                try {
                    // Получаем audioOutputMixer после инициализации SDK
                    audioOutputMixer.value = await getAudioOutputMixer(await sdk.value);
                    console.log("audioOutputMixer", audioOutputMixer);
                    console.log("audioOutputMixer", audioOutputMixer.value);


                    // Инициализируем localDevices
                    try {
                        localDevices.value = await getLocalDevices(await sdk.value);
                        console.log("localDevices", localDevices);
                        console.log("localDevices", localDevices.value);
                    } catch (e) {
                        console.log(e);
                    }

                    // Если необходимо, инициализируйте eventBus
                    // eventBus.value = sdk.value.eventBus;

                    // Подписываемся на события
                    if (!localDevices.value) {
                        return;
                    }

                    // const unsubscribeMuteChange = handleEvent(
                    //     localDevices.value.event
                    // )
                    const unsubscribeMuteChange = handleEvent(
                        localDevices.value!.event$,
                        'muteTrackChanged',
                        // async ({ payload }) => {
                        //     if (
                        //         payload.mediaType === 'displayScreen' &&
                        //         payload.stream === displayStream.value
                        //     ) {
                        //         isMutedDisplay.value = payload.isMuted;

                        //         if (payload.isMuted) {
                        //             displayStream.value = undefined;
                        //         }
                        //     }
                        // },
                        (event) => {
                            const { payload } = event;
                            if (
                                payload.mediaType === 'displayScreen' &&
                                payload.stream === displayStream.value
                            ) {
                                isMutedDisplay.value = payload.isMuted;

                                if (payload.isMuted) {
                                    displayStream.value = undefined;
                                }
                            }
                        },
                    );


                    // const unsubscribeErrorDevicePermission = handleEvent(
                    //     localDevices.value!.event$,
                    //     'errorDevicePermissions',
                    //     (event) => {
                    //         const { payload } = event;
                    //         if (eventBus.value) {
                    //             eventBus.value({
                    //                 type: 'error',
                    //                 payload: {
                    //                     title: payload.message,
                    //                 },
                    //             });
                    //         } else {
                    //             console.error('Device permission error:', payload.message);
                    //         }
                    //     }
                    // );

                    onUnmounted(() => {
                        unsubscribeMuteChange();
                        // unsubscribeErrorDevicePermission();
                    });
                } catch (e) {
                    console.log(e);
                }


            } catch (error) {
                console.error('Error initializing SDK:', error);
            }
        }

        async function joinRoom() {
            jazzClient = await createJazzClient(await sdk.value!, {
                serverUrl: 'https://salutejazz.ru',
            });

            const sdkToken = await createSdkToken('eyJwcm9qZWN0SWQiOiIxYzBmZjI1Mi01YTEzLTQ4OTItYjdiMy04ZDVjNmIxNjlhNzQiLCJrZXkiOnsia3R5IjoiRUMiLCJkIjoiYkZjWWNpcG5kSkFqM21rZXdyX1VVVm9HdTlueEZqQXlIZWUwWi1oNl9WYmEzR29QYzlEZzY1MzhHWHI1a191ZiIsInVzZSI6ImVuYyIsImNydiI6IlAtMzg0Iiwia2lkIjoiYjRlZmJkNGYtNmI4ZC00Mzc0LThjMGEtYWExOTg5ZTVlNzMyIiwieCI6Ijlwa0prZ3NVOU9SRzVpbVNVUk5XNlVDVk9BUjl5WEQtNkFaYXZOVFZ0LWJfOFRxMXhETjM3REpEelpPM3M2cFUiLCJ5IjoiWHQ1RzJZWmFiaEdjTWtMeU4yNThuNXQzMWR3ek9rZFVadk1SN3VKMThWRHgxdVFDamQ0YXA5aE5tamNJVDN4MyJ9fQ==', {
                iss: 'PlatformaApp',
                userName: props.userName,
            });

            console.log(sdkToken);

            await jazzClient.auth.loginBySdkToken(sdkToken.sdkToken);

            const conference = await jazzClient.conferences.createConference({
                title: '',
                isGuestEnabled: true,
            });

            try {
                console.log("Started joining to room");
                room = await jazzClient.conferences.join({
                    conferenceId: props.conferenceId,
                    password: props.conferencePassword,
                });
                try {

                    const [audioStreaml, videoStreaml] = await Promise.all([
                        localDevices.value!.getSelectedAudioInputStream({ isMuted: false }),
                        localDevices.value!.getSelectedVideoInputStream({ isMuted: false }),
                    ]);


                    console.log('Add mediaStreams to room');

                    room.setUserAudioInput(audioStreaml);
                    room.setUserVideoInput(videoStreaml);
                    // room.setUserVideoInput(displayStream.value!);

                    const releaseMedia = () => {
                        console.log('Release mediaStreams');

                        localDevices.value!.releaseMediaStream(audioStreaml);
                        localDevices.value!.releaseMediaStream(videoStreaml);
                        // localDevices.value!.releaseMediaStream(displayStream.value!);
                    };
                    console.log("Some log");

                    handleEvent(room.event$, 'localTrackUpdated', releaseMedia, true);
                    // handleEvent(room.event$, 'JazzRoomEventLocalParticipantChanged', releaseMedia, true);
                    // handleEvent(room.event$, 'destroy', releaseMedia, true);
                    // handleEvent(room.event$, 'JazzRoomEventParticipantUpdate', releaseMedia, true);
                } catch (error) {
                    console.log('Media permission denied');
                }
                // room.displayStream = videoStream;
                // if (room) {
                //     this.sroom = room as JazzRoom; // Assign the JazzRoom object to sroom
                // }
                // const unsubscribe = handleQuery(room.localParticipant, (localParticipant) => {
                //     console.log("lp", localParticipant);
                // });
                // unsubscribe();

                // const unsubscribe2 = handleEvent(room.event$, 'participants', ({ payload }) => {
                //     console.log("ps", payload.participants);
                // });

                // unsubscribe2();

                console.log("ROOM IS ", room);
                const localParticipant = await room.localParticipant.get();
                const localParticipant2 = await room.participants;
                const ll = await localParticipant2.get();
                console.log("local participant is", localParticipant);
                console.log("participants is", ll);
                const dominantParticipiantId = room.dominantParticipantId.get();
                const dominantParticipiant = room.dominantParticipantId;
                console.log("dominantParticipiantId is ", dominantParticipiantId);
                console.log("dominantParticipiant is ", dominantParticipiant);
            } catch (error) {
                console.log("error is:", error);
            }

        }

        onMounted(async () => {
            await initSdk()
            .then(async () => {
                await joinRoom()
            });
        });
        return {
            currentStatusRef,
            displayVideoElementRef,
        };
    }
})
</script>

<style scoped>
    .wrapper-video {
        width: 560px;
        height: 300px;
        margin-bottom: 16px;
        overflow: hidden;
        border-radius: 16px;
        position: relative;
    }


    .video-muted {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--dark01);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white);
    }

    .video {
        pointer-events: none;
        height: 100%;
        object-fit: cover;
        width: 100%;
        display: block;
        background: var(--dark01);
    }

    .video[data-is-invert='true'] {
        transform: scaleX(-1);
    }

    .video[data-is-card='true'] {
        position: absolute;
        left: 8px;
        bottom: 8px;
        height: 100px;
        width: 100px;
        border-radius: 16px;
        box-shadow: 4px 4px 8px #00000038;
        z-index: 1;
    }

    .video[data-is-hidden='true'] {
        display: none;
    }

</style>