<template>
    <div class="page">
        <div class="logo-nav">
            <nav class="nav">
                <a 
                    href="#" 
                    @click.prevent="currentSection = 'events'"
                    :class="{ active: currentSection === 'events' }"
                >
                    Мероприятия
                </a>
                <a 
                    href="#" 
                    @click.prevent="currentSection = 'messages'"
                    :class="{ active: currentSection === 'messages' }"
                >
                    Сообщения
                    <span class="badge">9+</span>
                </a>
                <a 
                    href="#" 
                    @click.prevent="currentSection = 'favorites'"
                    :class="{ active: currentSection === 'favorites' }"
                >
                    Избранное
                </a>
                <a 
                    href="#" 
                    @click.prevent="currentSection = 'settings'"
                    :class="{ active: currentSection === 'settings' }"
                >
                    Настройки
                </a>
            </nav>
        </div>

        <main class="main">
            <h2 class="page-title">{{ pageTitle }}</h2>

            <!-- Events Section -->
            <div v-if="currentSection === 'events'" class="sections-container">
                <NewEventForm class="section" />
                <TemplatesSection class="section" />
                <CalendarSection class="section" />
            </div>

            <!-- Messages Section -->
            <div v-if="currentSection === 'messages'" class="messages-container">
                <div class="messages-content">
                    <h3>Входящие сообщения</h3>
                    <!-- Add messages content here -->
                </div>
            </div>

            <!-- Favorites Section -->
            <div v-if="currentSection === 'favorites'" class="favorites-container">
                <div class="favorites-content">
                    <h3>Избранные мероприятия</h3>
                    <!-- Add favorites content here -->
                </div>
            </div>

            <!-- Settings Section -->
            <div v-if="currentSection === 'settings'" class="settings-container">
                <div class="settings-content">
                    <h3>Настройки профиля</h3>
                    <!-- Add settings content here -->
                </div>
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import NewEventForm from '../components/pages/personal/NewEventForm.vue'
import TemplatesSection from '../components/pages/personal/TemplatesSection.vue'
import CalendarSection from '../components/pages/personal/CalendarSection.vue'

type Section = 'events' | 'messages' | 'favorites' | 'settings'

export default defineComponent({
    name: 'PersonalPage',
    components: {
        NewEventForm,
        TemplatesSection,
        CalendarSection,
    },
    setup() {
        const currentSection = ref<Section>('events')

        const pageTitle = computed(() => {
            switch (currentSection.value) {
                case 'events':
                    return 'Управляйте своим мероприятиями'
                case 'messages':
                    return 'Сообщения'
                case 'favorites':
                    return 'Избранное'
                case 'settings':
                    return 'Настройки'
                default:
                    return ''
            }
        })

        return {
            currentSection,
            pageTitle
        }
    },
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Tilda+Sans:wght@400;500;600&display=swap');

.page {
    min-height: 100vh;
    background: white;
    font-family: 'Tilda Sans', sans-serif;
}

.logo-nav {
    display: flex;
    align-items: center;
    gap: 40px;
}

.logo {
    color: #8162E9;
    font-size: 20px;
    font-weight: 600;
}

.nav {
    display: flex;
    gap: 32px;
}

.nav a {
    color: #322965;
    text-decoration: none;
    position: relative;
    font-size: 16px;
}

.nav a.active {
    color: #705CE2;
}

.badge {
    background: #F58C88;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    margin-left: 4px;
}

.search {
    position: relative;
}

.search input {
    width: 256px;
    height: 40px;
    padding: 0 40px 0 16px;
    border: 2px solid #B2A9FA;
    border-radius: 20px;
    background: white;
    color: #B2A9FA;
}

.search button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}

.main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.page-title {
    font-size: 32px;
    font-weight: 600;
    color: #1A1C26;
    margin-bottom: 32px;
}

.sections-container {
    display: flex;
    gap: 32px;
    margin: 0 -16px;
}

.section {
    flex: 1;
    min-width: 0;
    padding: 0 16px;
}

.messages-container,
.favorites-container,
.settings-container {
    background: white;
    border-radius: 8px;
    padding: 24px;
}

.messages-content,
.favorites-content,
.settings-content {
    max-width: 800px;
    margin: 0 auto;
}

h3 {
    font-size: 24px;
    font-weight: 600;
    color: #1A1C26;
    margin-bottom: 24px;
}

@media (max-width: 1200px) {
    .sections-container {
        flex-direction: column;
    }

    .section {
        width: 100%;
    }
}
</style>