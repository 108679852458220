import { createRouter, createWebHistory } from 'vue-router'

import AllEvents from '../views/AllEvents.vue';
import AllMentors from '../views/AllMentors.vue';
import MentorPage from '../views/MentorPage.vue';
import HomePage from '../views/HomePage.vue';
import EventInfo from '../views/EventInfoPage.vue';
import RegisterPage from '../views/RegisterPage.vue';
import LoginPage from '../views/LoginPage.vue';
import TestPage from '../views/TestPage.vue';
import NewTest from '../views/NewTestPage.vue';
import SaluteJazzTest from '../views/SaluteJazzTest.vue';
import CobferencePage from '../views/ConferencePage.vue';
import ConferencePage from '../views/ConferencePage.vue';
import PersonalPage from '../views/PersonalPage.vue';
import PersonalPage2 from '../views/PersonalPage2.vue';
import MeetingPage from '@/views/MeetingPage.vue';
import UploadPage from '../views/UploadPage.vue'
// import TestPage2 from '../views/TestPageTest2.vue';
// import HomeView from './HomeView.vue'
// import AboutView from './AboutView.vue'

const routes = [
    { path: '/Home', component: HomePage },
    //   { path: '/about', component: AboutView },
    { path: '/AllEvents', component: AllEvents },
    { path: '/AllMentors', component: AllMentors },
    { path: '/', component: HomePage },
    { path: '/event_info/:id/', name: 'EventInfo', component: EventInfo },
    { path: '/mentor_info/:mentorId', name: 'MentorInfo', component: MentorPage },
    { path: '/register', component: RegisterPage },
    { path: '/login', component: LoginPage },
    { path: '/TestPage', component: TestPage },
    { path: '/NewTest', component: NewTest },
    { path: '/SaluteJazzTest', component: SaluteJazzTest },
    { path: '/ConferencePage', component: ConferencePage, meta: { requiresAuth: false } },
    { path: '/MeetingPage/:id/', component: MeetingPage },
    { path: '/PersonalPage', component: PersonalPage },
    { path: '/PersonalPage2', component: PersonalPage2 },
    { path: '/UpdatePage', component: UploadPage },
    //   { path: '/TestPage2', component: TestPage2 },
    { path: '/dashboard', component: () => import('../views/DashboardPage.vue'), meta: { requiresAuth: true } },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(/* to, from, savedPosition */) {

        // if (savedPosition) {
        // If there's a saved scroll position (e.g., after using the browser's back button), use that.
        //   return savedPosition;
        // } else {
        // Otherwise, scroll to the top of the page.
        return { top: 0 };
        // }
    }
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('accessToken');
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next('/login');  // Если не авторизован, перенаправляем на страницу входа
    } else {
        next();  // В противном случае, разрешаем переход
    }
});

// const router = new VueRouter({
//     mode: history,
//     base: process.env.BASE_URL, 
//     routes,
// })

export default router



