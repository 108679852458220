<template>
    <div class="spacing-160">
    </div>
</template>

<style scoped>
html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
}
    .spacing-160 {
        height: 160px;
    }
</style>

<script>
  export default {
    name: 'Spacer-160'
  }
</script>