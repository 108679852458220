<template>
    <div class="spacing-48">
    </div>
</template>

<style scoped>
html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
}
    .spacing-48 {
        height: 48px;
    }
</style>

<script>
  export default {
    name: 'Spacer-48'
  }
</script>