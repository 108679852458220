<template>
    <div class="templates-section">
        <h3 class="section-title">Мои шаблоны</h3>
        <p class="section-description">
            Используйте сохранённые шаблоны чтобы создать аналогичное мероприятие
        </p>

        <div v-if="templates.length > 0" class="templates-list">
            <div v-for="template in templates" :key="template.id" class="template-item">
                <div class="template-icon">+</div>
                <div class="template-info">
                    <h4 class="template-title">{{ template.title }}</h4>
                    <p class="template-details">{{ template.time }} · {{ template.duration }}</p>
                </div>
            </div>
        </div>
        <p v-else class="no-templates">Пока что у вас нет шаблонов</p>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
    name: 'TemplatesSection',
    setup() {
        const templates = ref([]);

        const fetchTemplates = async () => {
            // Simulating an API call
            // In a real application, you would make an actual API call here
            const response = await new Promise(resolve => {
                setTimeout(() => {
                    resolve([
                        { id: 1, title: 'Сияй изнутри: питание..', time: '12:00', duration: '1 час' },
                        { id: 2, title: 'Сияй изнутри: питание..', time: '12:00', duration: '1 час' }
                    ]);
                }, 1000);
            });
            templates.value = response;
        };

        onMounted(fetchTemplates);

        return {
            templates
        };
    }
});
</script>

<style scoped>
.templates-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.section-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.templates-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.template-item {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 12px;
}

.template-icon {
    width: 40px;
    height: 40px;
    background-color: #e0e0e0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 15px;
}

.template-info {
    flex: 1;

}

.template-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.template-details {
    font-size: 14px;
    color: #666;
}

.no-templates {
    text-align: center;
    color: #666;
    font-style: italic;
}
</style>