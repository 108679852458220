<template>
    <header class="header">
        <a href="/">
            <img src="../assets/images/logo/logo.svg" alt="Логотип">
        </a>
        <nav class="nav">
            <a href="/AllMentors" class="nav-link">Менторы</a>
            <a href="/AllEvents" class="nav-link">Мероприятия</a>
            <!-- <router-link :to="Mentor">Mentors</router-link>
      <router-link :to="Mentor">Events</router-link>
      <router-view/> -->
        </nav>
        <div v-if="is_logged">
            <!-- Здесь можно добавить иконку пользователя для авторизованного пользователя -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
              <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
            </svg>
        </div>
        <div v-else>
            <a href="/login" class="login-button">
                <!-- Иконка пользователя перед текстом входа -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="16px" height="16px" style="vertical-align: middle; margin-right: 8px;">
                  <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
                </svg>
                Вход
            </a>
        </div>

    </header>
</template>

<script>
export default {
    name: 'HeaderComponent',
    components: {
        // IconCross,
        // IconArrowRight,
        // FlatsRowItemMobile,
        // FlatsRowItem,
        // IconTriangleArrow,
        // IconClose,
    },
    props: {
        is_logged: Boolean,
    }
}

</script>

<style scoped>
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.header {
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
    background-color: #FAF7FD;
    border-radius: 32px;
}

.nav {
    display: flex;
    gap: 28px;
    padding: 0px 30px 0px 30px;
}

.nav-link {
    text-decoration: none;
    color: gray;
    font-size: 16px;
    padding: 4px 8px 4px 8px;
    border-radius: 32px;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    /* Длительность увеличена до 0.5s и плавный переход */
}

.nav-link:hover {
    background-color: #dcd8fc;
    cursor: pointer;
    transform: scale(1.02);
    /* Небольшое увеличение карточки при наведении */
    opacity: 0.95;
    /* Изменение прозрачности */
    transition-delay: 0.01s;
    /* Задержка перед началом анимации */
}


.router-link {
    text-decoration: none;
    color: gray;
    font-size: 16px;
}

.login-button {
    background-color: #6a41f2;
    max-height: 44px;
    max-width: 94px;
    color: white;
    border: none;
    padding: 12px 24px 12px 24px;
    border-radius: 32px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    /* Длительность увеличена до 0.5s и плавный переход */

}

.login-button:hover {
    background-color: #322965;
    color: white;
    cursor: pointer;
    transform: scale(1.02);
    /* Небольшое увеличение карточки при наведении */
    opacity: 0.95;
    /* Изменение прозрачности */
    transition-delay: 0.01s;
    /* Задержка перед началом анимации */
}
</style>