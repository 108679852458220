<template>
    <div class="register-form">
        <h2>Регистрация</h2>
        <form @submit.prevent="registerUser">
            <div class="form-group">
                <label for="username">Username</label>
                <input v-model="form.username" id="username" class="form-control" placeholder="Username" required />
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input v-model="form.email" id="email" type="email" class="form-control" placeholder="Email" required />
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input v-model="form.password" id="password" type="password" class="form-control" placeholder="Password"
                    required />
            </div>
            <div class="form-group">
                <label for="mentor_name">Name</label>
                <input v-model="form.mentor_name" id="mentor_name" class="form-control" placeholder="Name" />
            </div>
            <div class="form-group">
                <label for="mentor_surname">Surname</label>
                <input v-model="form.mentor_surname" id="mentor_surname" class="form-control" placeholder="Surname" />
            </div>
            <div class="form-group">
                <label for="mentor_description">Description</label>
                <input v-model="form.mentor_description" id="mentor_description" class="form-control"
                    placeholder="Description" />
            </div>
            <!-- <div class="form-group">
                <label for="mentor_skills">Skills (comma separated)</label>
                <input v-model="form.mentor_skills" id="mentor_skills" class="form-control"
                    placeholder="Skills (comma separated)" />
            </div> -->
            <div class="form-group">
                <label for="skills">Skills</label>
                <div v-for="(skill, index) in form.mentorSkills" :key="index" class="dynamic-input">
                    <input type="text" v-model="form.mentorSkills[index]" class="form-control" placeholder="Skill" />
                    <button type="button" @click="removeSkillField(index)" class="remove-btn">−</button>
                </div>
                <button type="button" @click="addSkillField" class="add-btn">+ Add Skill</button>
            </div>

            <div class="form-group">
                <label for="mentor_work_expirience">Work Experience</label>
                <input v-model="form.mentor_work_expirience" id="mentor_work_expirience" type="number"
                    class="form-control" placeholder="Work Experience" />
            </div>

            <!-- <div class="form-group">
          <label for="mentor_education">Education (comma separated)</label>
          <input v-model="form.mentor_education" id="mentor_education" class="form-control" placeholder="Education (comma separated)" />
        </div> -->
            <div class="form-group">
                <label for="education">Education</label>
                <div v-for="(education, index) in form.mentorEducation" :key="index" class="dynamic-input">
                    <input type="text" v-model="form.mentorEducation[index]" class="form-control"
                        placeholder="Education" />
                    <button type="button" @click="removeEducationField(index)" class="remove-btn">−</button>
                </div>
                <button type="button" @click="addEducationField" class="add-btn">+ Add Education</button>
            </div>
            <div class="form-group">
                <label for="user_role">Role</label>
                <input v-model="form.user_role" id="user_role" class="form-control" placeholder="Role" />
            </div>
            <div class="form-group">
                <label for="user_city_residence">City of Residence</label>
                <input v-model="form.user_city_residence" id="user_city_residence" class="form-control"
                    placeholder="City of Residence" />
            </div>
            <button type="submit" class="btn btn-outline-dark">Register me!</button>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            form: {
                username: '',
                email: '',
                password: '',
                mentor_name: '',
                mentor_surname: '',
                mentor_description: '',
                mentorSkills: [''],
                mentor_work_expirience: 0,
                mentorEducation: [''],
                user_role: '',
                user_city_residence: ''
            }
        };
    },
    methods: {
        addEducationField() {
            this.form.mentorEducation.push(''); // Добавляет новое поле
        },
        removeEducationField(index) {
            if (this.form.mentorEducation.length > 1) {
                this.form.mentorEducation.splice(index, 1); // Удаляет поле
            }
        },
        addSkillField() {
            this.form.mentorSkills.push(''); // Добавляет новое поле
        },
        removeSkillField(index) {
            if (this.form.mentorSkills.length > 1) {
                this.form.mentorSkills.splice(index, 1); // Удаляет поле
            }
        },
        registerUser() {
            const formData = {
                ...this.form,
                mentor_skills: this.form.mentorSkills,
                mentor_education: this.form.mentorEducation,
            };

            axios.post('https://platforma34.tw1.ru/api/users/register/', formData)
                .then(() => {
                    alert('User registered successfully');
                })
                .catch(error => {
                    console.error(error);
                    alert('Registration failed');
                });
        }
    }
};
</script>

<style scoped>
.register-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.register-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
    /* Увеличено расстояние между полями */
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-control {
    width: 100%;
    padding: 12px;
    /* Добавлено больше отступов внутри input */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    /* Учитываем padding и border в общей ширине */
}

.btn {
    width: 100%;
    padding: 12px;
    background-color: #6a41f2;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.btn:hover {
    background-color: #380470;
}

.add-btn,
.remove-btn {
    background-color: #6a41f2;
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    margin-left: 5px;
    border-radius: 4px;
}

.remove-btn {
    background-color: #DEB887;
}

.remove-btn:hover {
    background-color: #c2b280;
}

.add-btn {
    margin-top: 10px;
}

.add-btn:hover {
    background-color: #380470;
}
</style>