import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "logo-nav" }
const _hoisted_3 = { class: "nav" }
const _hoisted_4 = { class: "main" }
const _hoisted_5 = { class: "page-title" }
const _hoisted_6 = {
  key: 0,
  class: "sections-container"
}
const _hoisted_7 = {
  key: 1,
  class: "messages-container"
}
const _hoisted_8 = {
  key: 2,
  class: "favorites-container"
}
const _hoisted_9 = {
  key: 3,
  class: "settings-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewEventForm = _resolveComponent("NewEventForm")!
  const _component_TemplatesSection = _resolveComponent("TemplatesSection")!
  const _component_CalendarSection = _resolveComponent("CalendarSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.currentSection = 'events'), ["prevent"])),
          class: _normalizeClass({ active: _ctx.currentSection === 'events' })
        }, " Мероприятия ", 2),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.currentSection = 'messages'), ["prevent"])),
          class: _normalizeClass({ active: _ctx.currentSection === 'messages' })
        }, _cache[4] || (_cache[4] = [
          _createTextVNode(" Сообщения "),
          _createElementVNode("span", { class: "badge" }, "9+", -1)
        ]), 2),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.currentSection = 'favorites'), ["prevent"])),
          class: _normalizeClass({ active: _ctx.currentSection === 'favorites' })
        }, " Избранное ", 2),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.currentSection = 'settings'), ["prevent"])),
          class: _normalizeClass({ active: _ctx.currentSection === 'settings' })
        }, " Настройки ", 2)
      ])
    ]),
    _createElementVNode("main", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.pageTitle), 1),
      (_ctx.currentSection === 'events')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_NewEventForm, { class: "section" }),
            _createVNode(_component_TemplatesSection, { class: "section" }),
            _createVNode(_component_CalendarSection, { class: "section" })
          ]))
        : _createCommentVNode("", true),
      (_ctx.currentSection === 'messages')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "messages-content" }, [
              _createElementVNode("h3", null, "Входящие сообщения")
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.currentSection === 'favorites')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[6] || (_cache[6] = [
            _createElementVNode("div", { class: "favorites-content" }, [
              _createElementVNode("h3", null, "Избранные мероприятия")
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.currentSection === 'settings')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[7] || (_cache[7] = [
            _createElementVNode("div", { class: "settings-content" }, [
              _createElementVNode("h3", null, "Настройки профиля")
            ], -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}