import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "myText"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JumpingDotLoaderWithShadows = _resolveComponent("JumpingDotLoaderWithShadows")!
  const _component_ConferencePage2 = _resolveComponent("ConferencePage2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_JumpingDotLoaderWithShadows, {
      isLoading: (_ctx.currentStatus == 'Fetching event' || _ctx.currentStatus == 'Checking the registration'),
      "loading-text": _ctx.currentStatus
    }, null, 8, ["isLoading", "loading-text"]),
    (_ctx.currentStatus == 'Connecting to conference')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ConferencePage2, {
            currentStatus: _ctx.currentStatus,
            conferenceId: _ctx.eventData?.value?.event_conference_id ?? '',
            conferencePassword: _ctx.eventData?.value?.event_conference_password ?? '',
            userName: "Max",
            eventTitle: _ctx.eventData?.value?.event_title ?? ''
          }, null, 8, ["currentStatus", "conferenceId", "conferencePassword", "eventTitle"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.currentStatus), 1))
  ]))
}