import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// import DateFormat from '@voidsolutions/vue-dateformat';
import axios from 'axios';
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

const app = createApp(App);
app.use(router);
app.use(VCalendar);
// app.use(DateFormat);

app.mount('#app');

// const cors=require("cors");
// const corsOptions ={
//    origin:'*', 
//    credentials:true,            //access-control-allow-credentials:true
//    optionSuccessStatus:200,
// }

// app.use(cors(corsOptions)) // Use this after the variable declaration

/* eslint-disable */

const tokenString = localStorage.getItem('token');
let token = null;

// if (tokenString) {
//   try {
//     token = await JSON.parse(tokenString);
//   } catch (error) {
//     console.error('Failed to parse token from localStorage:', error);
//   }
// }

if (token) {
//   axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
} else {
  // Handle the case where the token is not available
}

