<template>
    <div class="spacing-8">
    </div>
</template>

<style scoped>
html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
}
    .spacing-8 {
        height: 8px;
    }
</style>

<script>
  export default {
    name: 'Spacer-8'
  }
</script>