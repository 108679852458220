<template>
    <div style="display: flex; flex-direction: column;">
        <JumpingDotLoaderWithShadows
        :isLoading="(currentStatus == 'Fetching event' || currentStatus == 'Checking the registration')"
        :loading-text="currentStatus" />
        <div  v-if="currentStatus == 'Connecting to conference'">
            <ConferencePage2 :currentStatus="currentStatus" :conferenceId="eventData?.value?.event_conference_id ?? ''" :conferencePassword="eventData?.value?.event_conference_password ?? ''" userName="Max" :eventTitle="eventData?.value?.event_title ?? ''"/>
        </div>
        <div v-else class="myText">{{ currentStatus }}</div>
        
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
/* eslint-disable */
import VueJwtDecode from 'vue-jwt-decode';
import { JazzClient, JazzRoom, JazzSdk, createJazzWebSdk, createJazzClient, handleEvent, LocalDevicesManager, getLocalDevices } from '@salutejs/jazz-sdk-web';
import { getAudioOutputMixer, AudioOutputMixer, videoElementPoolPlugin, audioOutputMixerPlugin } from '@salutejs/jazz-sdk-web-plugins';



import axios from 'axios';
import JumpingDotLoaderWithShadows from '../components/Animations/JumpingDotLoaderWithShadows.vue';
import ConferencePage2 from './ConferencePage2.vue';




export default defineComponent({
    components: {
        JumpingDotLoaderWithShadows,
        ConferencePage2,
    },
    setup() {
        const sdk = ref<JazzSdk | undefined>(undefined);

        const eventData = ref<any | undefined>(undefined);
        const error = ref<any | undefined>(undefined);
        const route = useRoute();

        var jazzClient: JazzClient;
        var room: JazzRoom;

        const audioOutputMixer = ref<AudioOutputMixer | undefined>(undefined);
        const localDevices = ref<LocalDevicesManager | undefined>(undefined);
        const isMutedDisplay = ref(true);
        const displayStream = ref<MediaStream | undefined>(undefined);

        const Statuses = ["Fetching event", "Checking the registration", "Connecting to conference", "Unregistered User", "Connection failed", "Error in fetching event", "error"];
        const currentStatus = ref("Fetching event");

        async function fetchEventData() {
            console.log(currentStatus.value)
            try {
                // eslint-
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvent/?id=' + route.params.id); // must be replaced by params
                if (!response.data) {
                    currentStatus.value = "Error in fetching event";
                    console.log(currentStatus.value)
                    throw new Error('Failed to fetch event data');
                }
                eventData.value = await response.data;
                console.log("event Data: ", eventData.value);
            } catch (err) {
                error.value = err;
            }
        };

        async function checkEventRegistration() {
            currentStatus.value = "Checking the registration";
            console.log(currentStatus.value)
            const memberId = await VueJwtDecode.decode(localStorage.getItem("accessToken")).user_id
            if (await eventData.value.event_members_id.indexOf(memberId) >= 0) {
                console.log(memberId)
                return true;
            }
            return false;
        };

        async function joinConference() {

            jazzClient = await createJazzClient(await sdk.value!, {
                serverUrl: 'https://salutejazz.ru',
            });
        }

        async function initsdk() {
            try {
                sdk.value = await createJazzWebSdk({
                    plugins: [
                        videoElementPoolPlugin(),
                        audioOutputMixerPlugin(),
                        // logsPlugin({
                        // logLevel: 'debug',
                        // }),
                    ],
                });
                console.log("sdk:", sdk);
                console.log("sdk.value:", sdk.value);



                try {
                    // Получаем audioOutputMixer после инициализации SDK
                    audioOutputMixer.value = await getAudioOutputMixer(await sdk.value);
                    console.log("audioOutputMixer", audioOutputMixer);
                    console.log("audioOutputMixer", audioOutputMixer.value);


                    // Инициализируем localDevices
                    try {
                        localDevices.value = await getLocalDevices(await sdk.value);
                        console.log("localDevices", localDevices);
                        console.log("localDevices", localDevices.value);
                    } catch (e) {
                        console.log(e);
                    }

                    // Если необходимо, инициализируйте eventBus
                    // eventBus.value = sdk.value.eventBus;

                    // Подписываемся на события
                    if (!localDevices.value) {
                        return;
                    }

                    // const unsubscribeMuteChange = handleEvent(
                    //     localDevices.value.event
                    // )
                    const unsubscribeMuteChange = handleEvent(
                        localDevices.value!.event$,
                        'muteTrackChanged',
                        // async ({ payload }) => {
                        //     if (
                        //         payload.mediaType === 'displayScreen' &&
                        //         payload.stream === displayStream.value
                        //     ) {
                        //         isMutedDisplay.value = payload.isMuted;

                        //         if (payload.isMuted) {
                        //             displayStream.value = undefined;
                        //         }
                        //     }
                        // },
                        (event) => {
                            const { payload } = event;
                            if (
                                payload.mediaType === 'displayScreen' &&
                                payload.stream === displayStream.value
                            ) {
                                isMutedDisplay.value = payload.isMuted;

                                if (payload.isMuted) {
                                    displayStream.value = undefined;
                                }
                            }
                        },
                    );


                    // const unsubscribeErrorDevicePermission = handleEvent(
                    //     localDevices.value!.event$,
                    //     'errorDevicePermissions',
                    //     (event) => {
                    //         const { payload } = event;
                    //         if (eventBus.value) {
                    //             eventBus.value({
                    //                 type: 'error',
                    //                 payload: {
                    //                     title: payload.message,
                    //                 },
                    //             });
                    //         } else {
                    //             console.error('Device permission error:', payload.message);
                    //         }
                    //     }
                    // );

                    onUnmounted(() => {
                        unsubscribeMuteChange();
                        // unsubscribeErrorDevicePermission();
                    });
                } catch (e) {
                    console.log(e);
                }


            } catch (error) {
                console.error('Error initializing SDK:', error);
            }
        }

        onMounted(async () => {
            await fetchEventData()
            .then(async () => {
                const isRegisteredForEvent = await checkEventRegistration();
                if (isRegisteredForEvent) {
                    currentStatus.value = "Connecting to conference";
                    // await initsdk()
                    //     .then(async () => {
                    //         await joinConference();
                    //     })
                }

            })

            // currentStatus.value = "Connecting to conference";
        });


        return {
            error,
            currentStatus,
            fetchEventData,
            checkEventRegistration,
        };
    },
})
</script>

<style scoped></style>