<template>
    <div class="border">
        <div class="col col-left">
            <div class="txtleftcolumn">
                <div class="title">
                    Станьте наставником для увлеченных людей со всего мира
                </div>
                <div class="text">
                    Делитесь своими знаниями и зарабатывайте на этом. 
                    Проводите индивидуальные занятия или открытые мастер-классы, 
                    а мы найдем вам первых учеников и возьмем всю организационную работу на себя
                </div>
                <Spacer16/>
                <Spacer16/>
                <div class="buttons">
                    <div @click="navigateToPage('/register')" class="button1">
                        Заполнить анкету
                    </div>
                    <div class="button2">
                        Подробнее
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-right">
            <img src='../../assets/images/landing/tormen.svg' alt='Top Left Image' class="tormen-img">
        </div>
        <div class="col col-right">
            <div class="magic">
                <div class="photos">
                    <img v-for="(src, index) in this.imageURLs" :key="index" :src="src" :alt="'Image ' + (index + 1)" class="overlay-image" :style="getStyle(index)">
                </div>
                <div>
                    <div class="text1">
                        2000+ наставников уже с нами
                    </div>
                    <Spacer8/>
                    <div class="text2">
                        Специалисты в разных сферах и с разным опытом
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Spacer16 from '../Spacers/SpacerModule16.vue';
    import Spacer8 from '../Spacers/SpacerModule8.vue';
    
    export default {
        name: 'HomeQuestionerMain',
        components: {
            Spacer16,
            Spacer8,
        },
        data() {
            return {
                imageURLs: [
                    'https://s3-alpha-sig.figma.com/img/b1e6/e548/44b48618b8df4fb8c189557f81d99294?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=S~uwlBxrej0bZAX1R2OsTz~v85dQNIgaHqKUaUv6md1u5OHDtzr~-vREWOjMt~zr9zODNMbgY1Xt9QokuC8obLcp-1J~yyXsKsYX5kCB89SE6QuUdtEuD9672YBn5PYHKoleckpWh2XA8mbi77kje7Exrzo3h2AHeAxLyURi1bpQ0Zr7yndkODLawVSYka8z5WrvNCiq5WmurzixZPSa6iSYaLAI5fj6Mr7fbArfP-fpOBdkAHtYzFS~kWoycdyd2xD2mgZmxCbSTBMJlDNb6evvv7i-1RuHUzRb5UGp1QgrAOVEpsr3DyWEPdckzcXN9P5T1iQDWWokyWZxIbzI5A__',
                    'https://s3-alpha-sig.figma.com/img/53f5/54ee/4ade7cb08329bbe77251e8cb150b059f?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nj0XC3scaDQdRYSe2~p6I5qS6EZ6QaCdKVzA-zMsgDXWocYnsZWTc2RXLDM9BETboCHSRRZuoE2MsLCpkbCp2gxMzdlEPNcCoeSo4gwqlRIq0zmUusG~o0deppIxOQoP5-w-YxeKyd~1GdWZMC-sndcHAPrn1laOIt9BRCDphvT7B8BdJx6ssCs8TDoXTBm3luCj2OH5tRnejvUOyd~5O5mJKLTNihYX8msUSVq4I3ufjUmAbazufIIpdlAOMhbHMcT6gRdZgQrK7IznC2MJMOz8KEgJFSkp8xMviwgWyuz0qSq5Ac7Yy60i4VCNeJCy1rw-LTgiIZWk7yjLCxS6-A__',
                    'https://s3-alpha-sig.figma.com/img/cc89/afb2/1a7862ddf9b337aeff2a42c0d7b5c74b?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SobjH3U9LRWRhvHW9oAzfy~gHl0sGS1Omh1nvmXmWHWedymew~eqkbD9AS0wnDcuyngnhDdoEQLaVELpnRWlX2eTZlRazh0kV9~hsvHUJIIppPj~2DJhynftF7BtK9vgliOPrCJ8GJpkpWuWPSqL6hIDm8b3hA6HRLtQmWfts9REYWjdZk88BlQOO4-X0Vmh1ay6Qw97Z9nrxqs-k-hOr5WIeNZjLQ8z6IsdKU0wH8bRqgiuXyICiPdB3mcHGqghEUoyZysVfcz9aND5k05WvTV9X3buKoEODYeyv6W0jP8T3VDLDs6ZxaUf5H9laMKXsyb5ZHGABKZzXWvgQPpj~A__',
                    'https://s3-alpha-sig.figma.com/img/ee61/2cfc/f69a82594ef979176629078c7d146eb2?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MI12HuCSj9DfDQdrPJCXxv0gQpWH~56F40h6bH2G4ypsSkTN8wBJSj4V6Vjb0cT~W~Z9OR4Rjg-fkTTLqyYH1i14k5iR4goRd8wnI87jbIG7hmDgMXgfvMg9FRXVcGC6JP8xMesX5NS-WrTwLo~Cufx22oz8M6P-gZr9t0fmT6IEs4WcZVWsfv0Jz7Wg5Rt8COV5H9nnRY4bWVMNNnu43zy3wNczFsaEUnHwXrJJ~ZIku1Kt8WARf2gyZIcSpwuojib2kBUbgIWpOsduY0ijDHseE0v-qQuW2-U6Z8QEuA8s7iF5iEYyBXxvqzfI2M2jSL6LfSbXBXCJ6cxP-~E9zw__',

                ],
                offset: 14,
            };
        },
        methods: {
            getStyle(index) {
                return {
                    marginLeft: index === 0 ? '0px' : `-${this.offset}px`,
                    zIndex: this.imageURLs.length + index,
                };
            },
            navigateToPage(route) {
                this.$router.push(route);
            }
        }
    }

</script>

<style scoped>
    html, body {
        margin: 0;
    }
    .border {
        display: grid;
        grid-template-columns: 2fr 1fr; /* Две колонки по ширине */
        grid-template-rows: 1fr 1fr; /* Две строки одинаковой высоты */
        height: 50em; /* Высота 95% */
        gap: 20px;
        color: #525A7D
    }

    .col {
        border-radius: 32px;
        background-color: #F3F4F5;
        display: grid; /* Используем Grid для центрирования текста */
    }

    .col-left {
        grid-column: 1; 
        grid-row: span 2;
    }  

    .col-right:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
    }

    .col-right:nth-child(3) {
        grid-column: 2; 
        grid-row: 2; 
    }

    .txtleftcolumn {
        padding: 156px 56px;
    }
    .title {
        font-size: 80px;
        font-weight: 550;
        line-height: 84px;
        letter-spacing: -0.02em;
        text-align: left;
    }
    .text {
        padding-top: 48px;
        max-width: 556px;
        font-size: 20px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        gap: 12px;
    }
    .button1 {
        display: flex; 
        border-radius: 32px;
        padding: 14px 24px;
        background-color: #7B65F7;
        color: white;
    }
    .button2 {
        display: flex; 
        border-radius: 32px;
        padding: 14px 24px;
        background-color: #fff;
        border: 1px solid #DAD9DC;
        color: #2F3448
    }
    .button1:hover {
        cursor: pointer;
        background-color: #5343a9;
    }
    .button2:hover {
        cursor: pointer;
        background-color: #DAD9DC
    }
    .overlay-image {
        border-radius: 100px;
        width: 64px;
        height: 64px;
        object-fit: cover;
        border: 2px solid white;
    }

    .overlay-image:first-child {
        margin-left: 0; /* No offset for the first image */
    }

    .photos {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        width: 50%;
        
        
        position: relative;
    }

    .magic {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px 20px;
    }

    .text1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.01em;
        text-align: left;
    }
   
    .text2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

    }

    .tormen-img {
        width: 100%;
        object-fit: cover;
    }
</style>
