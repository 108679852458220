<template>
    <div class="AboutMentor">
        <img src="https://avatars.dzeninfra.ru/get-zen_doc/271828/pub_65706bb45fdde42ef6e6f683_65706bbfdaf56139a33c767c/scale_2400" class="MentorImage"/>
        <div class="MentorDescription">
            <div class="MentorName">
                <!-- {{ this.currentMentor.mentor_name }} {{ this.currentMentor.mentor_surname }} -->
            </div>
            <Spacer-8/>
            <div class="MentorReview">
                <!-- <div class="star"></div> -->
                <div class="MentorReviewScore">
                    4.6
                </div>
                <div class="MentorReviewCount">
                    16 отзывов
                </div>
            </div>
            
            <Spacer-16/>
            <div class="MainText">
                <!-- {{ this.currentMentor.mentor_description }} -->
            </div>
            <Spacer-16/>
            <div class="MentorButtons"> 
                <link-to-mentor :mentorId="mentor_id">
                    <div class="MentorButton">Подробнее</div>
                </link-to-mentor>
                <div class="MentorButton">Оставить отзыв</div>
            </div>
        </div>
    </div>
</template>

<script>
import Spacer8 from '../components/Spacers/SpacerModule8.vue';
import Spacer16 from '../components/Spacers/SpacerModule16.vue';
import axios from 'axios';
import LinkToMentor from '../components/Links/LinkToMentor.vue';
// import VueJwtDecode from 'vue-jwt-decode'
// import moment from 'moment';

export default {
    name: "MentorCard",
    components: {
        Spacer16,
        Spacer8,
        LinkToMentor,
    },
    props: {
        mentor_id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            mentors: [],
            currentMentor: null,
            id: null,
            reviews: [],
            memberId: null,
        };
    },
    methods: {
        // formated(value) {
        //     var some_date = moment(String(value.toLocaleString('ru-RU'))).format('DD MMMM hh:mm');
        //     some_date = some_date.replace("September", "Сентября");
        //     some_date = some_date.replace("October", "Октября");
        //     some_date = some_date.replace("November", "Ноября");
        //     some_date = some_date.replace("December", "Декабря");
        //     some_date = some_date.replace("January", "Января");
        //     some_date = some_date.replace("February", "Февраля");
        //     some_date = some_date.replace("March", "Марта");
        //     some_date = some_date.replace("April", "Апреля");
        //     some_date = some_date.replace("May", "Мая");
        //     some_date = some_date.replace("June", "Июня");
        //     some_date = some_date.replace("July", "Июля");
        //     some_date = some_date.replace("August", "Августа");
        //     return some_date;
        // },
        async fetchData() {
            try {
                const response = await axios.get('https://platforma34.tw1.ru/api/users/GetUsers/');
                this.mentors = response.data;
                console.log(response.data)
                console.log(this.mentors)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchMentor() {
            try {
                const response = await axios.get('https://platforma34.tw1.ru/api/users/GetUser/?user_id='+this.mentor_id);
                this.currentMentor = response.data;
                console.log(response.data);
                console.log("this is current mentor :", this.currentMentor);
                console.log("this is current mentor for :", this.mentor_id);
            } catch (error) {
                console.error('Error fetching data:', error);
            } 
        },
        // async getIdFromToken() {
        //     this.memberId = await VueJwtDecode.decode(localStorage.getItem("accessToken")).user_id
        // },
        // async checkMember() {
        //     if (await this.currentEvent.event_members_id.indexOf(this.memberId) >= 0 ) {
        //         console.log(this.memberId)
        //         return true;
        //     }
        //     return false;
        // },
        // async registerMemberToEvent() {
        //     console.log(this.memberId)
        //     if (this.currentEvent.event_members_id.indexOf(this.memberId) >= 0 ) {
        //         return;
        //     } else {
        //         this.currentEvent.event_members_id.push(this.memberId);
        //     }
        //     try {
        //         axios.put('https://platforma34.tw1.ru/api/events/UpdateEvent/?id='+this.$route.params.id, {
        //             'event_members_id': this.currentEvent.event_members_id
        //         });
        //     } catch (e) {
        //         console.log("AN ERROR ACCURED"+e);
        //     }
        // },
        

    },
    mounted() {
        // this.id 
        // console.log(this.id)
        this.id = this.$route.params.id;
        this.fetchMentor();
        // this.fetchData()
        // this.fetchReviews()
        // this.getIdFromToken()
    },

    // ________________________________________________
    // export default {
    // data() {
    //     return {
    //     userIds: [],
    //     };
    // },
    // methods: {
    //     async addUserId() {
    //     const userId = await this.getUserId(); // Assume this function fetches the logged user's ID
    //     const response = await fetch('/api/userlist/add_user_id/', {
    //         method: 'POST',
    //         headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.getItem('token')}`, // If you're using JWT
    //         },
    //         body: JSON.stringify({ user_id: userId }),
    //     });
    //     const data = await response.json();
    //     if (response.ok) {
    //         this.userIds = data.user_ids; // Update the local user IDs
    //     } else {
    //         console.error(data);
    //     }
    //     },
    //     async getUserId() {
    //     // Implement the logic to retrieve the logged-in user's ID
    //     // For example, return a hardcoded user ID or fetch it from your store/state
    //     return 1; // Example user ID
    //     },
    // },
    // };
    // ______________
}
</script>

<style scoped> 
#eventInfo {
    padding: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.category-image {
    width: 28px;
    border-radius: 14px;
    padding-right: 5px;
}

.AtherDatesButton {
    padding: 12px 24px 12px 24px;
    border-radius: 24px;
    background-color: #ffffff;
    color: #454152;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.RegisteredButton {
    padding: 12px 24px 12px 24px;
    border-radius: 24px;
    background-color: #EBECEE;
    color: #434966;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.RegistrationButton {
    padding: 12px 24px 12px 24px;
    border-radius: 24px;
    background-color: #454152;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1); /* Длительность увеличена до 0.5s и плавный переход */
}

.RegistrationButton:hover {
    background-color: #6553ca;
    color: white;
    cursor: pointer;
    transform: scale(1.02); /* Небольшое увеличение карточки при наведении */
    opacity: 0.95; /* Изменение прозрачности */
    transition-delay: 0.01s; /* Задержка перед началом анимации */
}

.RegistrationSlotsInfo {
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;
    color: #454152;
    padding: 4px 8px 4px 8px;
    border-radius: 12px;
    border: 1px solid #454152;
}

.RegistrationInfoText {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #454152;
}

.EventRegistrationDateInfo {
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.EventRegistrationInfoItem {
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.CategoryItem {
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CategoryItemText {
    background: white;
    padding: 4px 8px 4px 8px;
    min-width: 10px;
    max-width: 100px;
    max-height: 20px;
    height: fit-content;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #DAD9DC;
    font-size: 13px;
    font-weight: 500;
    color: #454152;
}

.lll {
    text-align: bottom;
    padding: 24px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
}

.EventAbout {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: bottom;
    color: #454152;
}

.EventName {
    font-size: 56px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: bottom;
    color: #454152;
}

.InfoBlock {
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.AboutEventText {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #454152;
}

.MentorButtons {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.MentorButton {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    padding: 6px 24px 8px 24px;
    background: white;
    border-radius: 20px;
    border: 1px solid #DAD9DC;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1); /* Длительность увеличена до 0.5s и плавный переход */

}

.MentorButton:hover {
    background: #454152;
    color: white;
    cursor: pointer;
    transform: scale(1.02); /* Небольшое увеличение карточки при наведении */
    opacity: 0.95; /* Изменение прозрачности */
    transition-delay: 0.01s; /* Задержка перед началом анимации */
}

.MainText {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #454152;
}

.MentorReviewCount {
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    /*line-height: 16px;*/
    padding: 4px 8px;
    border: 1px solid #DAD9DC;
    border-radius: 20px;
    text-align: left;
}

.MentorReview {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.MentorReviewScore {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.MentorName {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #454152;
}

.star {
    position: absolute;
    left: 10.73%;
    right: 10.73%;
    top: 10.96%;
    bottom: 14.16%;

    border: 1.5px solid #454152;
    border-radius: 0.5px;

}

.chapter {
    color: #454152;
    font-family: 'PlatformaFont';
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;
}

.EventHero {
    display: grid;
    gap: 8px;
    width: 100%;
    grid-template-columns: 7fr 3fr;
}

.EventDescription {
    display: grid;
    gap: 8px;
    grid-template-rows: 3fr 1fr;

}

.EventInfoAbout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #F6F6F6;
    border-radius: 12px;
}

.EventRegistrationInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    background-color: #F6F6F6;
    border-radius: 12px;
    padding: 24px;
}

.EventImage {
    display: grid;
    border-radius: 12px;
    width: calc((100vw - 64px) * 3 / 10 - 8px);
    height: calc(calc((100vw - 64px) * 3 / 10 - 8px) * 4 / 3);
    object-fit: cover;
}

.AboutMentor {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 24px;
    background: #F6F6F6;
    border-radius: 12px;
}

.MentorDescription {
    display: flex;
    flex-direction: column;
}

.MentorImage {
    border-radius: 8px;
    height: 100%;
    width: 20%;
    object-fit: cover;
}

.EventSpecification {
    display: grid;
    grid-template-rows: 0.8fr 1.2fr;
    gap: 8px;
}

.EventAditionalInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
}

.EventRules {
    background-color: #F6F6F6;
    border-radius: 12px;
}

.EventRating {
    background-color: #F6F6F6;
    border-radius: 12px;
}

.EventAgeRestriction {
    background-color: #F6F6F6;
    border-radius: 12px;
}

.EventMembers {
    background-color: #F6F6F6;
    border-radius: 12px;
}

.card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px 8px;
    width: 100%;
}

.CategoryDesc {
    display: flex;
    flex-direction: row;

}
</style>
