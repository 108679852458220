<template>
    <div>
        <input type="file" @change="onFileChange" />
        <button @click="uploadImage">Upload Image</button>
        <div v-if="imageUrl">
            <h3>Uploaded Image:</h3>
            <img :src="imageUrl" alt="Uploaded" />
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "UploadPage",
    data() {
        return {
            selectedFile: null,
            imageUrl: null,
        };
    },
    methods: {
        onFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        async uploadImage() {
            const formData = new FormData();
            formData.append("image", this.selectedFile);

            try {
                const response = await axios.post("https://platforma34.tw1.ru/api/upload/UploadImage/", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                // Получаем URL загруженного изображения из ответа сервера
                this.imageUrl = response.data.imageUrl;
                console.log('this.imageUrl is ', this.imageUrl)
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        },
    },
};
</script>
