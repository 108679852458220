<template>
    <div class="HomeHow">
        <div class="title">
            Как начать сотрудничать
        </div>
        <Spacer64 />
        <div class="tiles">
            <div class="tile" v-for="(tile, index) in tiles" :key="tile.id" :class="'tile-' + (index + 1)">
                <tit>{{ tile.title }}</tit>
                <tex>{{ tile.description }}</tex>
            </div>
        </div>
        <div class="button-container">
            <div class="button" @click="navigateToPage('/register')">
                Заполнить анкету
            </div>
        </div>
        <div class="title">
            Плюсы работы с нами
        </div>
        <Spacer64 />
        <Spacer160/>
        <Spacer160/>
        <Spacer160/>
        <Spacer160/>
        <Spacer160/>
        <Spacer160/>
        <Spacer160/>
    </div>
</template>

<script>
import Spacer160 from '../Spacers/SpacerModule160.vue';
import Spacer64 from '../Spacers/SpacerModule64.vue';

export default {
    name: 'HomeHow',
    components: {
        Spacer64,
        Spacer160,
    },
    data() {
        return {
            tiles: [
                { id: 1, title: 'Заполните анкету', description: 
                'После отправки анкеты мы свяжемся с вами в WhatsApp или Telegram' },
                { id: 2, title: 'Пройдите интервью', description: 
                'Интервью длится 30 минут и проходит по Zoom в удобное для вас время' },
                { id: 3, title: 'Познакомьтесь с платформой', description: 
                'Расскажем о правилах работы сервиса, его особенностях и поможем с первыми клиентами' },
                { id: 4, title: 'Попробуйте открытые мастер-классы', description: 
                'Проведите групповое занятие и привлеките внимание к своим услугам, а мы поднимем \
                ваше событие в топ и поможем набрать участников' }
            ]
        }
    },
    methods: {
        navigateToPage(route) {
            this.$router.push(route);
        },
    },
}
</script>

<style scoped>
    .HomeHow {
        border-radius: 32px;
        background-color: #58B59A;
        color: #525A7D;
        padding-left: 32px;
        padding-right: 32px;
    }
    .title {
        padding-top: 60px;
        font-size: 64px;
        font-weight: 550;
        line-height: 72px;
        letter-spacing: -0.02em;
        text-align: center;
        color: white;
    }
    .tiles {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-bottom: 56px;
    }
    .tile {
        display: flex;
        flex-direction: column;
        
        border-radius: 20px;
        height: 22.5em;
        flex: 1;
        position: relative;
        margin-left: -38px; /* Накладывание плиток друг на друга */
    }
    .tile:first-child {
        margin-left: 0; 
    }
    .tile:nth-child(1) {
        background-color: white; 
        z-index: 1;
    }
    .tile:nth-child(2) {
        background-color: #ECF6F3; 
        z-index: 2;
    }
    .tile:nth-child(3) {
        background-color: #D7EDE6;
        z-index: 3;
    }
    .tile:nth-child(4) {
        background-color: #C1E4D8;
        z-index: 4;
    }
    .tile tit {
        padding: 24px 44px 20px 24px; /* top right bottom left */
        font-size: 32px;
        font-weight: 550;
        line-height: 36px;
        text-align: left;
        margin-bottom: auto;
    }
    .tile tex {
        padding: 24px 44px 20px 24px; /* top right bottom left */
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
    .button-container {
        display: flex;
        justify-content: center;
        padding-bottom: 100px;
    }
    .button {
        display: inline-flex; 
        padding: 14px 24px;
        border-radius: 32px;
        background-color: #F3F4F5;
        color: #525A7D
    }
    .button:hover {
        cursor: pointer;
        background-color: #DAD9DC
    }

</style>
