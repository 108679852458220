<template>
    <div id="mentors">
        <BackButtonVue />
        <Spacer-32 />
        <CategoryFilter :categories="categories" :selectedCategory="selectedCategory"
            @category-selected="filterByCategory">
            <DropdownMenuVue label="Формат" :options="formats" @select="handleFormatSelect" />
        </CategoryFilter>
        <Spacer-32/>
        <label>Work Experience</label>
        <input type="number" v-model="experience" placeholder="Точно" />
        <input type="number" v-model="experienceGt" placeholder="Больше" />
        <input type="number" v-model="experienceLt" placeholder="Меньше" />

        <button @click="applyFilters">Apply Filters</button>

        <Spacer-32 />
        <div class="card-list">
            <div v-for="mentor in mentors" :key="mentor.id">
                <link-to-mentor :id="mentor.id">
                    <MentorCardVue :mentor_id="mentor.id" />
                </link-to-mentor>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import CategoryFilter from '../components/CategoryFilter.vue';
import Spacer32 from '../components/Spacers/SpacerModule32.vue';
import BackButtonVue from '../components/Navigations/BackButton.vue';
import DropdownMenuVue from '../components/DropdownMenu.vue';
import MentorCardVue from "@/components/MentorCard.vue";

export default {
    name: 'AllMentors',
    components: {
        CategoryFilter,
        MentorCardVue,
        Spacer32,
        BackButtonVue,
        DropdownMenuVue,
    },
    data() {
        return {
            formats: ['Формат 1', 'Формат 2', 'Формат 3'],
            categories: [
                'Все категории', 'Здоровье', 'Карьера', 'Образование', 'Красота', 'Хобби', 'Просвещение'
            ],
            selectedCategory: 'Все категории',
            mentors: [],
            rating: null,
            ratingGte: null,
            ratingLte: null,
            experience: null,
            experienceGt: null,
            experienceLt: null,
        };
    },
    methods: {
        handleFormatSelect(option) {
            console.log('Selected format:', option);
        },
        async applyFilters() {
            try {
                const params = {
                    mentor_work_experience: this.experience,
                    mentor_work_experience_gt: this.experienceGt,
                    mentor_work_experience_lt: this.experienceLt,
                };
                console.log('https://platforma34.tw1.ru/api/users/GetUsers/', { params });
                const response = await axios.get('https://platforma34.tw1.ru/api/users/GetUsers/', { params });
                console.log(response)
                this.mentors = response.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
    },
    async mounted() {
        await this.applyFilters();
    }
}
</script>

<style scoped>

.card-list {
    display: grid;
    margin: 0;
    padding: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px 8px;
    width: 100%;
}

#events {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>
