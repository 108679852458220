<template>
    <button class="back-button" @click="goBack">
      <span class="arrow arrow-left"></span>
      <span class="text">Назад</span>
    </button>
  </template>
  
  <script>
  export default {
    name: 'BackButton',
    methods: {
        goBack() {
            if (window.history.length >= 1) { // must be >1
                this.$router.go(-1); // Вернуться, если есть предыдущая страница
            } else {
                this.$router.push('/'); // Перейти на главную, если история пуста
            }
        }
    }
  }
  </script>
  
  <style scoped>
  .back-button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: #4a4a4a;
    font-size: 16px;
    cursor: pointer;
  }
  
  .arrow {
    width: 10px;
    height: 10px;
    border-top: 2px solid #4a4a4a;
    border-right: 2px solid #4a4a4a;
    margin-right: 10px;
}

.arrow-left {
    transform: rotate(-135deg);
}
  
    .text {
        font-size: 16px;
        color: #4a4a4a;
    }
</style>
