<template>
    <div>TestPage</div>
</template>>

<!-- 
<template>
    <div>
        <h1>Text</h1>
        <h2>New text</h2>
        <button @click="init_app">Инициализация приложения</button>
        <button @click="getPart">Инициализация приложения</button>
        <button @click="get_videspermaa">get_video</button>
        <button @click="get_local_devices">get_local_devices</button>
        <div>
            <video ref="videoElementRef" autoplay playsinline muted disablePictureInPicture :data-is-invert="true"
                :data-is-card="!isMutedDisplay" :data-is-hidden="isMutedCamera" class="video"></video>
        </div>

        <div>
            <button :disabled="cameraStatus === 'pending'" @click="toggleCamera">
                <IconCamera :isMuted="isMutedCamera" />
                Toggle camera
            </button>
        </div>
    </div>

</template>



<script lang="ts">
import { ref, defineComponent } from 'vue';
import {
    createJazzClient,
    createJazzWebSdk,
    JazzSdk,
    JazzClient,
    createSdkToken,
    // handleQuery,
    // handleEvent,
    JazzRoom,
    getLocalDevices,

} from '@salutejs/jazz-sdk-web';
import { audioOutputMixerPlugin, videoElementPoolPlugin, getVideoElementPool, VideoElementPool, getAudioOutputMixer } from '@salutejs/jazz-sdk-web-plugins';
/* eslint-disable */
type Status = 'idle' | 'success' | 'error' | 'pending';
export default defineComponent({
    name: 'TestPage',
    data() {
        return {
            sroom: null as JazzRoom | null,
            sdk: null as JazzSdk | null,
            videoStream: ref<MediaStream | undefined>(undefined),
            audioStream: ref<MediaStream | undefined>(undefined),
            displayStream: ref<MediaStream | undefined>(undefined),
            videoElementRef: ref<HTMLVideoElement | undefined>(undefined),
        };
    },
    // data(): {
    //     sroom: JazzRoom | null,
    //     sdk: JazzSdk | null,
    //     videoStream: typeof ref<MediaStream | undefined>,
    //     audioStream: typeof ref<MediaStream | undefined>,
    //     displayStream: typeof ref<MediaStream | undefined>,
    //     videoElementRef: typeof ref<HTMLVideoElement | undefined>,
    // } {
    //     return {
    //         sroom: null,
    //         sdk: null,
    //         videoStream: ref(undefined),
    //         audioStream: ref(undefined),
    //         displayStream: ref(undefined),
    //         videoElementRef: ref(undefined),
    //     };
    // },
    // data(): {
    //     sroom: JazzRoom | null,
    //     sdk: JazzSdk | null,
    //     videoStream: ref<MediaStream | undefined> | undefined,
    //     audioStream: ref<MediaStream | undefined> | undefined,
    //     displayStream: ref<MediaStream | undefined> | undefined,
    //     videoElementRef: ref<HTMLVideoElement | undefined> | undefined,

    //     // isMutedCamera : ref<true>;
    //     // isMutedAudio : ref<true>;
    //     // isMutedDisplay : ref<true>;

    //     // cameraStatus : ref<Status> | 'idle';
    //     // audioStatus : ref<Status>('idle');
    //     // displayStatus : ref<Status>('idle');

    //     // displayVideoElementRef : ref<HTMLVideoElement | null>(null);

    //     // const localDevices = ref(sdk ? getLocalDevices(sdk) : undefined);
    //     // const audioOutputMixer = ref(sdk ? getAudioOutputMixer(sdk) : undefined);
    // } {
    //     return {
    //         sroom: null,
    //         sdk: null,
    //         videoStream: null,
    //         audioStream: null,
    //         displayStream: null,
    //         videoElementRef: null,
    //         // audioStatus : null,
    //         // cameraStatus: null,
    //     };
    // },
    // components: {
    //     ViewVideo
    // },
    watch: {
        videoStream(newStream, oldStream) {
            if (this.videoElementRef!.value!) {
                this.videoElementRef!.value!.srcObject = newStream || null;
                this.videoElementRef!.value!.load();
                this.videoElementRef!.value!.play().catch(() => { });
            }
        }
    },
    methods: {
        async init_app() {

            try {
                console.log('SDK code started');
                const jazzSdk: JazzSdk = await createJazzWebSdk({
                    plugins: [
                        videoElementPoolPlugin(),
                        audioOutputMixerPlugin(),
                        // logsPlugin({
                        // logLevel: 'debug',
                        // }),
                    ],
                });
                this.sdk = jazzSdk
                console.log('SDK jazz created');
                console.log(typeof (jazzSdk));

                const jazzClient: JazzClient = await createJazzClient(jazzSdk, {
                    serverUrl: 'https://salutejazz.ru',
                });

                console.log('SDK jazz client');
                console.log(typeof (jazzClient));

                const sdkToken = await createSdkToken('eyJwcm9qZWN0SWQiOiIxYzBmZjI1Mi01YTEzLTQ4OTItYjdiMy04ZDVjNmIxNjlhNzQiLCJrZXkiOnsia3R5IjoiRUMiLCJkIjoieEtnTTBtQ21VTDBsWlNoNmFjLUdaWUZZNkFfZmRZLTJNdWxXS2U5blJyb0dBMXBWUDBoU0xmTThYbUhKVkcxTyIsInVzZSI6ImVuYyIsImNydiI6IlAtMzg0Iiwia2lkIjoiMDQxOTc3N2ItZWE5YS00YWRhLWIyZTctODlhNWM2NjM4OWNiIiwieCI6ImFsSkF2S3RMX3FEaVhzOVFiOWEzUEdJcEhyVmdGQndDb2RIWU1sUXRuSGdQY3J3LTBvWGZjdE9NSFd2YVQxbzUiLCJ5Ijoib0NwU0F5d2E0cVl0dUttWVZoa2E2UnhEZEtId1AteXBMbmU2cS1mMXN4cmVjZzZCSUFzLWF0ZDdKYzAwakJtViJ9fQ==', {
                    iss: 'PlatformaApp',
                    userName: 'DPKlitr',
                });

                console.log(sdkToken);

                await jazzClient.auth.loginBySdkToken(sdkToken.sdkToken);

                const conference = await jazzClient.conferences.createConference({
                    title: 'My video call',
                    isGuestEnabled: true,
                });

                console.log('Conference created:', conference);

                console.log(conference);
                console.log('conference url is', conference.url);
                console.log('conference id is', conference.id);

                const conferenceID: string = conference.id;
                const conferencePassWord: string = conference.password;
                console.log("Conference Id:", conferenceID);
                console.log("Conference password:", conferencePassWord);
                try {
                    console.log("Started joining to room");
                    var room = await jazzClient.conferences.join({
                        conferenceId: conferenceID,
                        password: conferencePassWord,
                    });
                    if (room) {
                        this.sroom = room as JazzRoom; // Assign the JazzRoom object to sroom
                    }
                    // const unsubscribe = handleQuery(room.localParticipant, (localParticipant) => {
                    //     console.log("lp", localParticipant);
                    // });
                    // unsubscribe();

                    // const unsubscribe2 = handleEvent(room.event$, 'participants', ({ payload }) => {
                    //     console.log("ps", payload.participants);
                    // });

                    // unsubscribe2();
                    console.log("ROOM IS ", room);
                    const localParticipant = await room.localParticipant.get();
                    const localParticipant2 = await room.participants;
                    const ll = await localParticipant2.get();
                    console.log("local participant is", localParticipant);
                    console.log("participants is", ll);
                    const dominantParticipiantId = room.dominantParticipantId.get();
                    const dominantParticipiant = room.dominantParticipantId;
                    console.log("dominantParticipiantId is ", dominantParticipiantId);
                    console.log("dominantParticipiant is ", dominantParticipiant);
                } catch (error) {
                    console.log("error is:", error);
                }

            } catch (error) {
                console.error('Error initializing Jazz SDK:', error);
            }
        },
        async getPart() {
            try {
                console.log("ROOM IS ", this.sroom!);
                const localParticipant = await this.sroom!.localParticipant.get();
                const localParticipant2 = await this.sroom!.participants;
                const ll = await localParticipant2.get();
                console.log("local participant is", localParticipant);
                console.log("participants is", ll);
                const dominantParticipiantId = this.sroom!.dominantParticipantId.get();
                const dominantParticipiant = this.sroom!.dominantParticipantId;
                console.log("dominantParticipiantId is ", dominantParticipiantId);
                console.log("dominantParticipiant is ", dominantParticipiant);
            } catch (error) {
                console.log("error is:", error);
            }
        },


        async get_videspermaa() {
            try {
                console.log("process was started")
                const videoElementPool = ref<VideoElementPool | null>(null);
                console.log(videoElementPool)
                videoElementPool.value = getVideoElementPool(this.sroom!);
                console.log("process was ended with")
            } catch (error) {
                console.log("error is:", error);
            }
        },

        async get_local_devices() {
            try {
                console.log("process was started");
                var devices = getLocalDevices(this.sdk!)
                console.log("devices are")
                console.log(devices)
                console.log("process was ended with");
                if (this.videoStream)
                    devices.releaseMediaStream(this.videoStream!)
            } catch (error) {
                console.log("error is:", error);
            }

            try {
                console.log("process getAudioOutputMixer was started");
                var audioMixer = getAudioOutputMixer(this.sdk!)
                console.log("audioMixer are")
                console.log(audioMixer)
                console.log("process was ended with");
            } catch (error) {
                console.log("error is:", error);
            }

        },

        // async toggleCamera() {
        //     if (this.localDevices.value) return;

        //     cameraStatus.value = 'pending';

        //     try {
        //         if (isMutedCamera.value) {
        //         const video = await localDevices.value.getSelectedVideoInputStream({ isMuted: false });
        //         videoStream.value = video;
        //         isMutedCamera.value = false;
        //         cameraStatus.value = 'success';
        //         } else {
        //         if (videoStream.value) {
        //             await localDevices.value.releaseMediaStream(videoStream.value);
        //             isMutedCamera.value = true;
        //             cameraStatus.value = 'success';
        //         }
        //         }
        //     } catch (error) {
        //         cameraStatus.value = 'error';
        //     }
        // };

    },
    mounted() {
    }
})
</script> -->
