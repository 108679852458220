<template>
    <div class="filters">
        <div class="category-filter">
        <button
            v-for="category in categories"
            :key="category"
            :class="['filter-button', { active: category === selectedCategory }]"

            @click="selectCategory(category)"
        >
            {{ category }}
        </button>
       
        </div>
        <slot>

        </slot>
    </div>
  </template>

<script>
// defineProps({
//     categories: Array,
//     selectedCategory: String,
// });

// function selectCategory(category) {

// }
export default {
  name: 'CategoryFilter',
  props: {
    categories: {
      type: Array,
      required: true
    },
    selectedCategory: {
      type: String,
      required: true
    },
    selectCategory: {
        type: Function,
        required: true
    }
  },
//   methods: {
//     selectCategory(category) {
//         this.selectedCategory = category
//         this.$emit('category-selected', category);
//         console.log(this.selectedCategory)
//     }
//   }
}
</script>

<style scoped>
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
}

.category-filter {
  display: flex;
  gap: 10px;
}

.filter-button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
}

.filter-button.active {
  background-color: #6a41f2;
  color: #fff;
  border: 1px solid #6a41f2;
}

.filters {
    justify-content: space-between;
    align-items: center;
    display: flex;
} 
</style>