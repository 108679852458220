<template>
    <div>
        PIDR
    </div>
</template>
<!-- <template>
    <div class="wrapper">
        <p class="title">Test toggle audio and video</p>
        <div style="display: flex">
            <div class="wrapper-video">
                <video ref="videoElementRef" autoplay playsinline :data-is-invert="true" class="video"></video>
            </div>
             <div class="wrapper-video">        
                <video ref="displayVideoElementRef" autoplay playsinline  class="video"></video>
            </div>
           
        </div>
        <div class="actions">
           
            <div @click="toggleCamera" class="button">
              
                Toggle camera
            </div>
            <div @click="toggleAudio" class="button">
              
                Toggle audio
            </div>
            <div @click="toggleDesktop" class="button">
              
                Toggle desktop
            </div>
            <div @click="joinRoom" class="button">
              
                Join room
            </div>
            <div @click="getParticipantsInfo" class="button">
              
                Get participants
            </div>
           
                <IconDisplayScreen :isMuted="isMutedDisplay" />
                Toggle display
            </Button>
            <Button :isLoading="audioStatus === 'pending'" @click="toggleAudio">
                <IconAudio :isMuted="isMutedAudio" />
                Toggle audio
            </Button>
        </div>
      
      
        <ul>
            <li v-for="participant in myParticipants" v-bind:key="participant.id" style="display: flex; flex-direction: column; background-color: orange;">
                {{  participant.name }}
               
            </li>
        </ul>
            
        
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import {
    defineComponent,
    ref,
    watch,
    onMounted,
    onUnmounted,
} from 'vue';
import {
    getLocalDevices,
    //   handleEvent,
    ScreenShareUserCanceledError,
    createSdkToken,
    createJazzWebSdk,
    LocalDevicesManager,
    JazzSdk,
    handleEvent,
    JazzClient,
    createJazzClient,
    JazzRoom

    //   EventLike,
    //   LocalDevicesEventMuteTrackChanged,
} from '@salutejs/jazz-sdk-web';
import { getAudioOutputMixer, AudioOutputMixer, videoElementPoolPlugin, audioOutputMixerPlugin } from '@salutejs/jazz-sdk-web-plugins';
import { Body2, Button } from '@salutejs/plasma-b2c';
/* eslint-disable */

export default defineComponent({
    name: 'Lobby',
    components: {
        Body2,
        Button,
        // Ваши компоненты иконок...
    },
    /* eslint-disable */
    setup() {
        /* eslint-disable */
        const sdk = ref<JazzSdk | undefined>(undefined);
        // const sdk = ref<JazzSdk | undefined>(undefined);

        const audioOutputMixer = ref<AudioOutputMixer | undefined>(undefined);
        const eventBus = ref<any>(null); // Инициализируйте eventBus при необходимости

        const videoStream = ref<MediaStream | undefined>(undefined);
        const audioStream = ref<MediaStream | undefined>(undefined);
        const displayStream = ref<MediaStream | undefined>(undefined);

        const isMutedCamera = ref(true);
        const isMutedAudio = ref(true);
        const isMutedDisplay = ref(true);

        type Status = 'idle' | 'success' | 'error' | 'pending';

        const cameraStatus = ref<Status>('idle');
        const audioStatus = ref<Status>('idle');
        const displayStatus = ref<Status>('idle');

        const videoElementRef = ref<HTMLVideoElement | null>(null);
        const displayVideoElementRef = ref<HTMLVideoElement | null>(null);

        const localDevices = ref<LocalDevicesManager | undefined>(undefined);

        // const myParticipants = ref<any | undefined>(undefined);
        const myParticipants =  ref<any[]>([]);
        const participantStream = ref<{[key: number]: any}>({});
        // const myParticipants = ["a", "b", "c", "kakayatoHuinya"];

        var jazzClient: JazzClient;

        var room: JazzRoom;


        onMounted(async () => {
            try {
                sdk.value = await createJazzWebSdk({
                    plugins: [
                        videoElementPoolPlugin(),
                        audioOutputMixerPlugin(),
                        // logsPlugin({
                        // logLevel: 'debug',
                        // }),
                    ],
                });
                console.log("sdk:", sdk);
                console.log("sdk.value:", sdk.value);

                

                try {
                    // Получаем audioOutputMixer после инициализации SDK
                    audioOutputMixer.value = await getAudioOutputMixer(await sdk.value);
                    console.log("audioOutputMixer", audioOutputMixer);
                    console.log("audioOutputMixer", audioOutputMixer.value);


                    // Инициализируем localDevices
                    try {
                        localDevices.value = await getLocalDevices(await sdk.value);
                        console.log("localDevices", localDevices);
                        console.log("localDevices", localDevices.value);
                    } catch (e) {
                        console.log(e);
                    }

                    // Если необходимо, инициализируйте eventBus
                    // eventBus.value = sdk.value.eventBus;

                    // Подписываемся на события
                    if (!localDevices.value) {
                        return;
                    }

                    // const unsubscribeMuteChange = handleEvent(
                    //     localDevices.value.event
                    // )
                    const unsubscribeMuteChange = handleEvent(
                        localDevices.value!.event$,
                        'muteTrackChanged',
                        // async ({ payload }) => {
                        //     if (
                        //         payload.mediaType === 'displayScreen' &&
                        //         payload.stream === displayStream.value
                        //     ) {
                        //         isMutedDisplay.value = payload.isMuted;

                        //         if (payload.isMuted) {
                        //             displayStream.value = undefined;
                        //         }
                        //     }
                        // },
                        (event) => {
                            const { payload } = event;
                            if (
                                payload.mediaType === 'displayScreen' &&
                                payload.stream === displayStream.value
                            ) {
                                isMutedDisplay.value = payload.isMuted;

                                if (payload.isMuted) {
                                    displayStream.value = undefined;
                                }
                            }
                        },
                    );
                

                    const unsubscribeErrorDevicePermission = handleEvent(
                        localDevices.value!.event$,
                        'errorDevicePermissions',
                        (event) => {
                            const { payload } = event;
                            if (eventBus.value) {
                                eventBus.value({
                                    type: 'error',
                                    payload: {
                                        title: payload.message,
                                    },
                                });
                            } else {
                                console.error('Device permission error:', payload.message);
                            }
                        }
                    );

                    onUnmounted(() => {
                        unsubscribeMuteChange();
                        unsubscribeErrorDevicePermission();
                    });
                } catch (e) {
                    console.log(e);
                }


            } catch (error) {
                console.error('Error initializing SDK:', error);
            }
        });

        const cancelFn = ref<() => void>(() => { });

        cancelFn.value = () => {
            if (audioStream.value && audioOutputMixer.value) {
                audioOutputMixer.value.removeMediaStream(audioStream.value);
            }
        };

        onUnmounted(() => {
            cancelFn.value();
        });

        watch(
            () => videoStream.value,
            (newStream) => {
                if (videoElementRef.value) {
                    videoElementRef.value.srcObject = newStream || null;
                    videoElementRef.value.load();
                    videoElementRef.value.play().catch(() => { });
                }
            }
        );

        watch(
            [() => displayStream.value, () => isMutedDisplay.value],
            () => {
                if (displayVideoElementRef.value) {
                    const activeStream = !isMutedDisplay.value
                        ? displayStream.value
                        : null;
                    displayVideoElementRef.value.srcObject = activeStream || null;
                    displayVideoElementRef.value.load();
                    displayVideoElementRef.value.play().catch(() => { });
                }
            }
        );

        const getParticipantsInfo = async () => {
            myParticipants.value = await room.participants.get();
            console.log("myParticipants: ", myParticipants.value);
            for (const participant of myParticipants.value) {
                const stream = await room.getVideoStream(participant.id);
                participantStream.value[participant.id] = stream;
            }
        }

        // const getParticipantsInfo = async () => {
        //     // const unsubscribeParticipants = handleEvent(room.event$, 'participants', ({ payload }) => {
        //     handleEvent(room.event$, 'participants', ({ payload }) => {
        //         myParticipants.value = payload.participants
        //         // console.log(myParticipants);
        //         console.log("myParticipants: ", myParticipants.value);
        //     });
        //     // onUnmounted(() => {
        //         // unsubscribeParticipants();
        //     // });
        // }

        const joinRoom = async () => {
            // Инициальзация клиента 
            jazzClient = await createJazzClient(sdk.value!, {
                    serverUrl: 'https://salutejazz.ru',
                });

                console.log('SDK jazz client');
                console.log(typeof (jazzClient));


                const sdkToken = await createSdkToken('eyJwcm9qZWN0SWQiOiIxYzBmZjI1Mi01YTEzLTQ4OTItYjdiMy04ZDVjNmIxNjlhNzQiLCJrZXkiOnsia3R5IjoiRUMiLCJkIjoieEtnTTBtQ21VTDBsWlNoNmFjLUdaWUZZNkFfZmRZLTJNdWxXS2U5blJyb0dBMXBWUDBoU0xmTThYbUhKVkcxTyIsInVzZSI6ImVuYyIsImNydiI6IlAtMzg0Iiwia2lkIjoiMDQxOTc3N2ItZWE5YS00YWRhLWIyZTctODlhNWM2NjM4OWNiIiwieCI6ImFsSkF2S3RMX3FEaVhzOVFiOWEzUEdJcEhyVmdGQndDb2RIWU1sUXRuSGdQY3J3LTBvWGZjdE9NSFd2YVQxbzUiLCJ5Ijoib0NwU0F5d2E0cVl0dUttWVZoa2E2UnhEZEtId1AteXBMbmU2cS1mMXN4cmVjZzZCSUFzLWF0ZDdKYzAwakJtViJ9fQ==', {
                    iss: 'PlatformaApp',
                    userName: 'DPKlitr',
                });

                console.log(sdkToken);

                await jazzClient.auth.loginBySdkToken(sdkToken.sdkToken);

                // const conference = await jazzClient.conferences.createConference({
                //     title: 'My video call',
                //     isGuestEnabled: true,
                // });

                // console.log('Conference created:', conference);

                // console.log(conference);
                // console.log('conference url is', conference.url);
                // console.log('conference id is', conference.id);

                // const conferenceID: string = conference.id;
                // const conferencePassWord: string = conference.password;
                // console.log("Conference Id:", conferenceID);
                // console.log("Conference password:", conferencePassWord);

                // работа с румом


                try {
                    console.log("Started joining to room");
                    room = await jazzClient.conferences.join({
                        conferenceId: "nqz8nh",
                        password: "m7tbqar6",
                    });
                    // console.log("localDevices.value!.getSelectedAudioInputStream()", localDevices.value!.getSelectedAudioInputStream())
                    try {
                        // const [audioStreaml, videoStreaml] = [audioStream, videoStream];

                        const [audioStreaml, videoStreaml] = await Promise.all([
                            localDevices.value!.getSelectedAudioInputStream({isMuted: false}),
                            localDevices.value!.getSelectedVideoInputStream({isMuted: false}),
                        ]);
                        

                        console.log('Add mediaStreams to room');

                        room.setUserAudioInput(audioStreaml);
                        room.setUserVideoInput(videoStreaml);
                        // room.setUserVideoInput(displayStream.value!);

                        const releaseMedia = () => {
                            console.log('Release mediaStreams');

                            localDevices.value!.releaseMediaStream(audioStreaml);
                            localDevices.value!.releaseMediaStream(videoStreaml);
                            // localDevices.value!.releaseMediaStream(displayStream.value!);
                        };
                        console.log("Some log");

                        handleEvent(room.event$, 'localTrackUpdated', releaseMedia, true);
                        // handleEvent(room.event$, 'JazzRoomEventLocalParticipantChanged', releaseMedia, true);
                        // handleEvent(room.event$, 'destroy', releaseMedia, true);
                        // handleEvent(room.event$, 'JazzRoomEventParticipantUpdate', releaseMedia, true);
                    } catch (error) {
                        console.log('Media permission denied');
                    }
                    // room.displayStream = videoStream;
                    // if (room) {
                    //     this.sroom = room as JazzRoom; // Assign the JazzRoom object to sroom
                    // }
                    // const unsubscribe = handleQuery(room.localParticipant, (localParticipant) => {
                    //     console.log("lp", localParticipant);
                    // });
                    // unsubscribe();

                    // const unsubscribe2 = handleEvent(room.event$, 'participants', ({ payload }) => {
                    //     console.log("ps", payload.participants);
                    // });

                    // unsubscribe2();

                    console.log("ROOM IS ", room);
                    const localParticipant = await room.localParticipant.get();
                    const localParticipant2 = await room.participants;
                    const ll = await localParticipant2.get();
                    console.log("local participant is", localParticipant);
                    console.log("participants is", ll);
                    const dominantParticipiantId = room.dominantParticipantId.get();
                    const dominantParticipiant = room.dominantParticipantId;
                    console.log("dominantParticipiantId is ", dominantParticipiantId);
                    console.log("dominantParticipiant is ", dominantParticipiant);
                } catch (error) {
                    console.log("error is:", error);
                }
        };
        
        const toggleCamera = async () => {
            if (!localDevices.value) return;
            console.log('Log1: cameraStatus: ', cameraStatus.value);
            cameraStatus.value = 'pending';
            console.log('Log2: cameraStatus: ', cameraStatus.value);

            if (isMutedCamera.value) {
                console.log('Log3: cameraStatus: ', cameraStatus.value);
                console.log('Log3: localDevices: ', localDevices.value);

                localDevices.value
                    .getSelectedVideoInputStream({ isMuted: false })
                    .then((video: MediaStream) => {
                        console.log('Log3.1: cameraStatus: ', cameraStatus.value);
                        videoStream.value = video;
                        cameraStatus.value = 'success';
                        isMutedCamera.value = false;
                        // room.setUserVideoInput(videoStream.value!);
                        // localDevices.value!.releaseMediaStream(videoStream.value!);
                        console.log('Log4: cameraStatus: ', cameraStatus.value);
                    })
                    .catch(() => {
                        cameraStatus.value = 'error';
                    });
            } else {
                console.log('Log5: cameraStatus: ', cameraStatus.value);
                if (videoStream.value) {
                    localDevices.value
                        .releaseMediaStream(videoStream.value)
                        .then(() => {
                            cameraStatus.value = 'success';
                            isMutedCamera.value = true;
                            console.log('Log6: cameraStatus: ', cameraStatus.value ?? undefined);
                            // room.setUserVideoInput(videoStream.value);
                        })
                        .catch(() => {
                            cameraStatus.value = 'error';
                        });
                }
            }
        };

        const toggleAudio = async () => {
            if (!localDevices.value || !audioOutputMixer.value) return;

            audioStatus.value = 'pending';

            if (isMutedAudio.value) {
                localDevices.value
                    .getSelectedAudioInputStream({ isMuted: false })
                    .then((audio: MediaStream) => {
                        audioOutputMixer.value!.addMediaStream(audio);
                        audioStream.value = audio;
                        audioStatus.value = 'success';
                        isMutedAudio.value = false;
                    })
                    .catch(() => {
                        audioStatus.value = 'error';
                    });
            } else {
                if (audioStream.value) {
                    audioOutputMixer.value!.removeMediaStream(audioStream.value);
                    localDevices.value
                        .releaseMediaStream(audioStream.value)
                        .then(() => {
                            audioStatus.value = 'success';
                            isMutedAudio.value = true;
                        })
                        .catch(() => {
                            audioStatus.value = 'error';
                        });
                }
            }
        };

        const toggleDesktop = () => {
            if (!localDevices.value || !audioOutputMixer.value) return;

            displayStatus.value = 'pending';

            if (isMutedDisplay.value) {
                localDevices.value
                    .getDisplayInputStream()
                    .then((stream: MediaStream) => {
                        audioOutputMixer.value!.addMediaStream(stream);
                        displayStream.value = stream;
                        isMutedDisplay.value = false;
                        displayStatus.value = 'success';
                    })
                    .catch((error: unknown) => {
                        if (error instanceof ScreenShareUserCanceledError) {
                            displayStatus.value = 'idle';
                            return;
                        }
                        displayStatus.value = 'error';
                    });
            } else {
                if (displayStream.value) {
                    audioOutputMixer.value.removeMediaStream(displayStream.value);
                    localDevices.value
                        .releaseMediaStream(displayStream.value)
                        .then(() => {
                            displayStream.value = undefined;
                            isMutedDisplay.value = true;
                            displayStatus.value = 'success';
                        })
                        .catch(() => {
                            displayStatus.value = 'error';
                        });
                }
            }
        };

        return {
            isMutedCamera,
            isMutedAudio,
            isMutedDisplay,
            cameraStatus,
            audioStatus,
            displayStatus,
            videoElementRef,
            displayVideoElementRef,
            toggleCamera,
            toggleAudio,
            toggleDesktop,
            joinRoom,
            getParticipantsInfo,
            myParticipants,
        };
    },
});
</script>


<style scoped>
.wrapper {}

.title {
    margin-bottom: 16px;
}

.actions {
    display: flex;
    justify-content: center;
}

.actions>*:not(:last-child) {
    margin-right: 8px;
}

.wrapper-video {
    width: 560px;
    height: 300px;
    margin-bottom: 16px;
    overflow: hidden;
    border-radius: 16px;
    position: relative;
}

.video-muted {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--dark01);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.video {
    pointer-events: none;
    height: 100%;
    object-fit: cover;
    width: 100%;
    display: block;
    background: var(--dark01);
}

.video[data-is-invert='true'] {
    transform: scaleX(-1);
}

.video[data-is-card='true'] {
    position: absolute;
    left: 8px;
    bottom: 8px;
    height: 100px;
    width: 100px;
    border-radius: 16px;
    box-shadow: 4px 4px 8px #00000038;
    z-index: 1;
}

.video[data-is-hidden='true'] {
    display: none;
}

.icon-wrapper {
    margin-right: 4px;
}

.button {
    padding: 10px;
    background-color: orange;
}

.button:hover {
    cursor: pointer;
    background-color: green;
}
</style> -->