<template>
    <div>
        <!-- <div class="heaader-app"> -->
        <HeaderComponent></HeaderComponent>
        <!-- </div> -->
        <Spacer-32/>
        <router-view/>
    </div>
</template>

<script lang="ts">
import HeaderComponent from "./components/TheHeader.vue";
import Spacer32 from "./components/Spacers/SpacerModule32.vue";

export default {
    name: "App",
    components: {
        HeaderComponent,
        Spacer32,
    },
}
</script>

<style>
#app {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 32px;
    padding: 0;
    max-width: 100%;
    font-family: 'PlatformaFont';
}

@font-face {
    font-family: 'PlatformaFont';
    src: url('/root/Platforma_maxim/Platforma/static/frontend/vueapp/src/assets/fonts/TildaSans-VF_TTF_Web/TildaSans-VF.ttf') format('truetype');
}
</style>
