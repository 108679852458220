import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { class: "wrapper-video" }
const _hoisted_4 = {
  ref: "videoElementRef",
  autoplay: "",
  playsinline: "",
  "data-is-invert": true,
  class: "video"
}
const _hoisted_5 = { class: "wrapper-video" }
const _hoisted_6 = {
  ref: "displayVideoElementRef",
  autoplay: "",
  playsinline: "",
  class: "video"
}
const _hoisted_7 = { class: "actions" }
const _hoisted_8 = { class: "wrapper-video" }
const _hoisted_9 = { style: {"position":"absolute","top":"15px"} }
const _hoisted_10 = ["srcObject"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("p", { class: "title" }, "Test toggle audio and video", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("video", _hoisted_4, null, 512)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("video", _hoisted_6, null, 512)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCamera && _ctx.toggleCamera(...args))),
        class: "button"
      }, " Toggle camera "),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleAudio && _ctx.toggleAudio(...args))),
        class: "button"
      }, " Toggle audio "),
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleDesktop && _ctx.toggleDesktop(...args))),
        class: "button"
      }, " Toggle desktop "),
      _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.joinRoom && _ctx.joinRoom(...args))),
        class: "button"
      }, " Join room "),
      _createElementVNode("div", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.getParticipantsInfo && _ctx.getParticipantsInfo(...args))),
        class: "button"
      }, " Get participants ")
    ]),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myParticipants, (participant) => {
        return (_openBlock(), _createElementBlock("li", {
          key: participant.id,
          style: {"display":"flex","flex-direction":"column"}
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(participant.name), 1),
            _createElementVNode("video", {
              ref_for: true,
              ref: `video_${participant.id}`,
              srcObject: _ctx.participantStream[participant.id],
              autoplay: "",
              playsinline: "",
              class: "video"
            }, null, 8, _hoisted_10)
          ])
        ]))
      }), 128))
    ])
  ]))
}