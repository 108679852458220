import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "wrapper-video" }
const _hoisted_2 = { style: {"position":"absolute","top":"15px"} }
const _hoisted_3 = ["srcObject"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.conferenceInputID) = $event)),
      placeholder: "nqz8nh"
    }, null, 512), [
      [_vModelText, _ctx.conferenceInputID]
    ]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.conferenceInputPassWord) = $event)),
      placeholder: "m7tbqar6"
    }, null, 512), [
      [_vModelText, _ctx.conferenceInputPassWord]
    ]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.event_conference_id_input) = $event)),
      placeholder: "44"
    }, null, 512), [
      [_vModelText, _ctx.event_conference_id_input]
    ]),
    _createElementVNode("div", {
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.joinRoom && _ctx.joinRoom(...args))),
      class: "button"
    }, " Join room "),
    _createElementVNode("div", {
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.btn && _ctx.btn(...args))),
      class: "button"
    }, " btn "),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localParticioants, (participant) => {
        return (_openBlock(), _createElementBlock("li", {
          key: participant.id,
          style: {"display":"flex","flex-direction":"column"}
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(participant.name), 1),
            _createElementVNode("video", {
              ref_for: true,
              ref: `video_${participant.id}`,
              srcObject: _ctx.participantStream[participant.id],
              autoplay: "",
              playsinline: "",
              class: "video"
            }, null, 8, _hoisted_3)
          ])
        ]))
      }), 128))
    ])
  ]))
}