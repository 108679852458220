<template>
    <div class="spacing-16">
    </div>
</template>

<style scoped>
html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
}
    .spacing-16 {
        height: 16px;
    }
</style>

<script>
  export default {
    name: 'Spacer-16'
  }
</script>